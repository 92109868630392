import { Card } from "antd";
import React from "react";

const DashboardStatRight = ({ value, title, bg }) => {
  return (
    <Card
      hoverable={false}
      loading={false}
      className={`w-24 h-32 ${bg} border-none rounded-lg`}
    >
      <div className="flex flex-col items-center gap-1 -mt-5">
        <span className="text-2xl font-bold text-primary_white">{value}</span>
        <span className="text-sm font-semibold text-center text-text_black1 ">{title}</span>
      </div>
    </Card>
  );
};

export default DashboardStatRight;
