import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Typography } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { adminLogin } from "../helpers";
import { Login_left, Login_logo, Login_right } from "../assets";
import { decrypt, encrypt } from "../utils/Protector";
import {
  openErrorNotification,
  openSuccessNotification,
  openWarningNotification,
} from "../helpers/headers";

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("token") !== null) {
      sessionStorage.setItem("token", null);
    }
    if (
      localStorage.getItem("email") !== null &&
      localStorage.getItem("password") !== null
    ) {
      adminLogin(
        1,
        decrypt(localStorage.getItem("email")),
        decrypt(localStorage.getItem("password"))
      )
        .then((response) => {
          if (response.data.success) {
            sessionStorage.setItem("token", response.data.output.token);
            navigate("/dashboard");
            window.location.reload("/");
          }
        })
        .catch((error) => {
          openErrorNotification(error);
        });
    }
  }, []);

  const onFinish = async (values) => {
    await adminLogin(1, values.email, values.password)
      .then((response) => {
        if (
          response.data.success &&
          response.data.message === "User authenticated successfully."
        ) {
          openSuccessNotification("Login Success..!");
          sessionStorage.setItem("token", response.data.output.token);

          if (values.remember === true) {
            localStorage.setItem("email", encrypt(values.email));
            localStorage.setItem("password", encrypt(values.password));
            localStorage.setItem(
              "name",
              encrypt(
                response.data.output.user_data.first_name +
                  " " +
                  response.data.output.user_data.last_name
              )
            );
          }
          navigate("/dashboard");
          window.location.reload("/");
        } else {
          openWarningNotification(response.data.message);
        }
      })
      .catch((error) => {
        openErrorNotification(error);
      });
  };

  return (
    <div className="flex flex-row w-full">
      <div className="flex w-full">
        <div className="relative flex justify-start w-full">
          <img src={Login_left} alt="left" className="w-full h-screen " />
        </div>
        <div className="absolute flex items-center justify-center w-1/2 h-full">
          <img
            src={Login_logo}
            alt="loginlogo"
            className="flex max-w-[450px] w-auto max-h-[250px] h-auto "
          />
        </div>
      </div>
      <div className="flex w-full -ml-60">
        <div className="relative flex justify-end w-full">
          <img src={Login_right} alt="right" className="w-full h-screen" />
        </div>
        <div className="absolute flex items-center justify-center w-1/2 h-full ">
          <div className="flex flex-col items-center justify-center w-3/6 bg-header_yellow ml-72 h-3/5 rounded-3xl">
            <div className="flex">
              <Typography className="text-xl font-semibold text-text_blue1">
                Admin Login
              </Typography>
            </div>
            <div className="flex items-center justify-center w-full">
              <Form
                name="normal_login"
                className="flex flex-col w-3/4 mt-6"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
              >
                <Typography className="text-base font-medium text-text_blacklight">
                  E-mail
                </Typography>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your E-mail!",
                      type: "email",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined />}
                    type="email"
                    placeholder="E-Mail"
                    className="text-sm font-light text-text_blacklight bg-primary_white"
                  />
                </Form.Item>
                <Typography className="text-base font-medium text-text_blacklight">
                  Password
                </Typography>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Password!",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    type="password"
                    placeholder="Password"
                    className="text-sm font-light text-text_black1 bg-primary_white"
                  />
                </Form.Item>

                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="justify-center w-full h-8 mt-4 text-base font-semibold text-center text-primary_white bg-primary_bluedark"
                  >
                    Log in
                  </Button>
                </Form.Item>

                <div className="flex justify-center w-full">
                  <a
                    className="text-sm font-medium underline login-form-forgot text-text_gray"
                    href="/resetpassword"
                  >
                    Forgot Password?
                  </a>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
