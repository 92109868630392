import { Card } from "antd";
import React from "react";

const DashboardStatRightBox = ({value,title,color}) => {
  return (
    <Card
      hoverable={false}
      loading={false}
      className="h-24 bg-primary_white2 border-none  max-w-[176px] w-auto rounded-2xl"
    >
      <div className="flex flex-col items-center gap-1 -mt-5">
        <span className={`text-2xl  font-bold ${color}`}>{value}</span>
        <span className="text-sm font-semibold text-center text-text_black1">
          {title}
        </span>
      </div>
    </Card>
  );
};

export default DashboardStatRightBox;
