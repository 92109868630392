import { Form, Input, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  base_url,
  config,
  openErrorNotification,
  openWarningNotification,
} from "../helpers/headers";
import { ViewDeliveryHeroes } from "../componants";

const columns = [
  {
    title: "DeliveryHero Name",
    dataIndex: "deliveryheroName",
    key: "0",
    sorter: (a, b) => a.deliveryheroName.localeCompare(b.deliveryheroName),
    defaultSortOrder: "ascend",
  },
  {
    title: "Reg No",
    dataIndex: "regNo",
    key: "1",
    sorter: (a, b) => a.regNo.localeCompare(b.regNo),
    defaultSortOrder: "ascend",
  },
  {
    title: "Vehicle",
    dataIndex: "vehicle",
    key: "2",
    sorter: (a, b) => a.vehicle.localeCompare(b.vehicle),
    defaultSortOrder: "ascend",
  },
  {
    title: "Total Deliveries",
    dataIndex: "totalDeliveries",
    key: "3",
    sorter: (a, b) => a.totalDeliveries - b.totalDeliveries,
    defaultSortOrder: "ascend",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "4",
  },
];

const DeliveryHeroes = () => {
  const [dataArr, setDataArr] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [isOpenDataViewDrawer, setIsOpenDataViewDrawer] = useState(false);

  const handleDataViewDrawer = (dataSet) => {
    setSelectedData(dataSet);
    setIsOpenDataViewDrawer(true);
  };

  const closeDrawer = () => {
    setIsOpenDataViewDrawer(false);
  };

  useEffect(() => {
    loadDeliveryHeroes(1, 5);
  }, []);

  const loadDeliveryHeroes = (state, sellerType) => {
    setDataLoading(true);
    const data = {
      status: state,
      seller_type: sellerType,
    };

    axios
      .post(base_url + "view/all", data, config)
      .then((response) => {
        if (response.data.success) {
          setData(response.data.output);
          setDataArr(response.data.output);
        } else {
          openWarningNotification("Error!", response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification("Error!", error.response.data.message);
        } else if (error.response.status === 500) {
          openErrorNotification("Internal Server Error!", error.message);
        } else {
          openErrorNotification("Error!", error.message);
        }
      });
  };

  const setData = (dataArr) => {
    if (dataArr.length !== 0) {
      let tableData = [];
      dataArr.forEach((dataSet, x) => {
        let data = {
          key: x,
          deliveryheroName: dataSet?.hero_name,
          regNo: dataSet?.reg_number,
          vehicle: dataSet?.vehicle_type,
          totalDeliveries: dataSet?.total_deliveries,
          action: (
            <div className="flex flex-row gap-4 text-2xl text-primaryLight">
              <a
                onClick={() => {
                  handleDataViewDrawer(dataSet);
                }}
              >
                <EyeOutlined style={{ fontSize: "18px" }} />
              </a>
            </div>
          ),
        };
        tableData.push(data);
      });
      setTableData(tableData);
    } else {
      setTableData([]);
    }
    setDataLoading(false);
  };

  const searchData = (text) => {
    setDataLoading(true);
    let filtered = dataArr?.filter((data) => {
      return (
        data.hero_name.toLowerCase().includes(text.toLowerCase()) ||
        data.reg_number.toLowerCase().includes(text.toLowerCase()) ||
        data.vehicle_type.toLowerCase().includes(text.toLowerCase()) ||
        data.total_deliveries.toString().includes(text.toString())
      );
    });
    setData(filtered);
  };

  return (
    <div className="flex flex-col w-full gap-3 mt-3 ">
      <div className="flex flex-row items-center w-full justify-evenly">
        <Typography className="text-base sm:text-lg w-auto min-w-[140px] font-semibold text-text_blue1">
          All Delivery Heroes
        </Typography>

        <Form className="flex sm:justify-end w-auto lg:w-[70%] ">
          <div className="flex flex-row md:w-1/2 items-center h-auto sm:h-[35px] rounded-2xl">
            <Input
              className="rounded-full border-border_blue border-[1px]"
              type="text"
              placeholder="Search Join Requests by name or ID"
              onChange={(e) => {
                searchData(e.target.value);
              }}
              suffix={
                <SearchOutlined className="text-base font-bold site-form-item-icon text-border_blue" />
              }
            />
          </div>
        </Form>
      </div>

      {/* contents*/}
      <div className="relative">
        <div className="w-full">
          <Table
            dataSource={tableData}
            columns={columns}
            loading={dataLoading}
            showSorterTooltip={false}
            pagination={{
              size: "small",
              defaultPageSize: 5,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "20"],
              showQuickJumper: true,
            }}
          />
          {isOpenDataViewDrawer ? (
            <ViewDeliveryHeroes
              open={isOpenDataViewDrawer}
              onClose={closeDrawer}
              dataSet={selectedData}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeliveryHeroes;
