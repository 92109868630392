import { Divider, Drawer, Typography } from "antd";
import React from "react";

const DiscountDetails = ({ open, onClose, dataSet }) => {
  return (
    <Drawer
      title={"Discount Details"}
      placement="right"
      size={"large"}
      onClose={onClose}
      open={open}
      className="text-center"
    >
      <div className="flex flex-col items-center justify-center w-full">
        <div className="flex flex-row items-center justify-center w-full gap-8">
          <div className="flex ">
            <img className="flex w-20 h-20" src={dataSet?.image}  />
          </div>
          <div className="flex flex-col w-[60%]">
            <div className="flex flex-row justify-between w-full mb-6">
              <div className="flex flex-col">
                <Typography className="text-sm font-semibold text-left text-text_blue">
                  Name:
                </Typography>
                <Typography className="text-base font-medium text-left text-text_black2">
                  {dataSet?.store_data?.store_name}
                </Typography>
              </div>
              <div className="flex flex-col">
                <Typography className="text-sm font-semibold text-left text-text_blue">
                  Reg No:
                </Typography>
                <Typography className="text-base font-medium text-left text-text_black2">
                  {dataSet?.store_id}
                </Typography>
              </div>
            </div>
            <div className="flex flex-row justify-between w-full">
              <div className="flex flex-col">
                <Typography className="text-sm font-semibold text-left text-text_blue">
                  Total Deliveries:
                </Typography>
                <Typography className="text-base font-medium text-left text-text_black2">
                  {dataSet?.total_deliveries}
                </Typography>
              </div>
              <div className="flex flex-col">
                <Typography className="text-sm font-semibold text-left text-text_blue">
                  Total Earnings:
                </Typography>
                <Typography className="text-base font-medium text-left text-text_black2">
                  {dataSet?.total_earnings}
                </Typography>
              </div>
              <div className="flex flex-col">
                <Typography className="text-sm font-semibold text-left text-text_blue">
                  Ratings:
                </Typography>
                <Typography className="text-base font-medium text-left text-text_black2">
                  {dataSet?.rate}
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className="flex items-center justify-start w-[80%]">
          <div className="flex flex-col w-full gap-6">
            <div className="flex flex-col">
              <Typography className="text-sm font-semibold text-left text-text_blue">
                Food Name:
              </Typography>
              <Typography className="text-base font-medium text-left text-text_black2">
                {dataSet?.item_name}
              </Typography>
            </div>
            <div className="flex flex-col">
              <Typography className="text-sm font-semibold text-left text-text_blue">
                Discount %:
              </Typography>
              <Typography className="text-base font-medium text-left text-text_black2">
                {dataSet?.discount}
              </Typography>
            </div>
            <div className="flex flex-row w-full gap-20">
              <div className="flex flex-col">
                <Typography className="text-sm font-semibold text-left text-text_blue">
                  Start Date
                </Typography>
                <Typography className="text-base font-medium text-left text-text_black2">
                  {dataSet?.start_date}
                </Typography>
              </div>
              <div className="flex flex-col">
                <Typography className="text-sm font-semibold text-left text-text_blue">
                  End Date
                </Typography>
                <Typography className="text-base font-medium text-left text-text_black2">
                  {dataSet?.end_date}
                </Typography>
              </div>
            </div>
            <div className="flex flex-col">
              <Typography className="text-sm font-semibold text-left text-text_blue">
                Banner
              </Typography>
              <img className="flex rounded-lg h-28 w-72" src="" />
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default DiscountDetails;
