import { UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Typography } from "antd";
import React from "react";
import axios from "axios";
import {
  base_url,
  openErrorNotification,
  openSuccessNotification,
  openWarningNotification,
} from "../../helpers/headers";

const VerifyEmail = ({ setFormChanger, setEmail }) => {
  const checkEmail = (formData) => {
    const data = {
      email: formData.email,
    };

    axios
      .post(base_url + "request/admin/otp", data)
      .then((response) => {
        if (
          response.data.success &&
          response.data.message === "OTP generated."
        ) {
          console.log(response.data.output.OTP);
          setEmail(data.email);
          openSuccessNotification(response.data.message);
          setFormChanger(1);
        } else {
          openWarningNotification(
            response.data.message + " Please check your email address.."
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification("Error!" + error.response.data.message);
        } else if (error.response.status === 500) {
          openErrorNotification("Internal Server Error!" + error.message);
        } else {
          openErrorNotification("Error!" + error.message);
        }
      });
  };

  return (
    <div className="flex flex-col items-center justify-center w-4/6 min-w-[300px] bg-header_yellow ml-72 h-1/2 rounded-3xl">
      <Typography className="text-xl font-semibold text-text_blue1">
        Reset your Password
      </Typography>
      <div className="flex items-center justify-center w-full">
        <Form
          name="emailverify"
          className="flex flex-col w-4/5 mt-4"
          layout="vertical"
          onFinish={checkEmail}
        >
          {/* <Typography className="mb-1 ml-1 text-sm font-normal text-text_blacklight">
            Enter your E-Mail
          </Typography> */}
          <Form.Item
            name="email"
            label="Enter your E-Mail"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please Enter Your email..",
              },
            ]}
          >
            <Input
              type="email"
              prefix={<UserOutlined />}
              placeholder="E-Mail"
              className="text-sm font-light text-text_blacklight bg-primary_white"
            />
          </Form.Item>
          <Form.Item>
            <div className="flex justify-center w-full">
              <Button
                type="primary"
                htmlType="submit"
                className="w-5/6 h-8 mt-2 text-sm font-semibold text-center min-w-[200px] text-primary_white bg-primary_bluedark"
              >
                SEND VERIFICATION CODE
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default VerifyEmail;
