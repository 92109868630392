import { UserOutlined } from "@ant-design/icons";
import { Button, Drawer, Form, Input, Select, Typography } from "antd";
import React from "react";

const AddNewDiscount = ({ open, onClose }) => {
  return (
    <Drawer
      title={"Add New Discount"}
      placement="right"
      size={"large"}
      onClose={onClose}
      open={open}
      className="text-center"
    >
      <div className="flex items-center justify-center w-full">
        <Form
          name="create_discount"
          className="flex flex-col w-4/6"
          initialValues={{
            remember: false,
          }}
          onFinish={""}
        >
          <Form.Item
            name="category"
            rules={[
              {
                required: true,
                message: "Please select category!",
                type: "text",
              },
            ]}
          >
            <Typography className="pl-2 mb-2 text-sm font-medium text-left text-text_blue1">
              Select Category
            </Typography>
            <Select>
              <Select.Option value="demo">Demo</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="subcategory"
            rules={[
              {
                required: true,
                message: "Please select subcategory!",
                type: "text",
              },
            ]}
          >
            <Typography className="pl-2 mb-2 text-sm font-medium text-left text-text_blue1">
              Select Subcategory
            </Typography>
            <Select>
              <Select.Option value="demo">Demo</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="restaurant"
            rules={[
              {
                required: true,
                message: "Please select restaurant!",
                type: "text",
              },
            ]}
          >
            <Typography className="pl-2 mb-2 text-sm font-medium text-left text-text_blue1">
              Select Restaurant
            </Typography>
            <Select>
              <Select.Option value="demo">Demo</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="item"
            rules={[
              {
                required: true,
                message: "Please select item!",
                type: "text",
              },
            ]}
          >
            <Typography className="pl-2 mb-2 text-sm font-medium text-left text-text_blue1">
              Select Item
            </Typography>
            <Select>
              <Select.Option value="demo">Demo</Select.Option>
            </Select>
          </Form.Item>
          <div className="flex flex-row gap-4">
            <Form.Item>
              <Typography className="pl-2 mb-2 text-sm font-medium text-left text-text_blue1">
                Discount %
              </Typography>
              <Input className="flex w-[90%] " />
            </Form.Item>
            <div className="flex flex-row justify-between w-[70%] mt-6">
              <div className="flex flex-col">
                <Typography className="text-xs font-medium text-left text-text_blacklight">
                  Current Price:
                </Typography>
                <Typography className="text-xs font-medium text-left text-text_blacklight">
                  1000.00
                </Typography>
              </div>
              <div className="flex flex-col">
                <Typography className="text-xs font-medium text-left text-text_blacklight">
                  Discount Amount:
                </Typography>
                <Typography className="text-xs font-medium text-left text-text_blacklight">
                  -100.00
                </Typography>
              </div>
              <div className="flex flex-col">
                <Typography className="text-xs font-medium text-left text-text_blacklight">
                  New Price:
                </Typography>
                <Typography className="text-xs font-medium text-left text-text_blacklight">
                  900.00
                </Typography>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-between">
            <Form.Item>
              <Typography className="pl-2 mb-2 text-sm font-medium text-left text-text_blue1">
                Start Date
              </Typography>
              <Input className="flex " />
            </Form.Item>
            <Form.Item>
              <Typography className="pl-2 mb-2 text-sm font-medium text-left text-text_blue1">
                End Date
              </Typography>
              <Input className="flex " />
            </Form.Item>
          </div>
          <Form.Item>
            <Typography className="pl-2 mb-2 text-sm font-medium text-left text-text_blue1">
              Banner
            </Typography>
            <Input className="flex " />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="justify-center w-[40%] mt-2 rounded-3xl h-8 text-base font-medium text-center text-primary_white bg-primary_bluedark"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
};

export default AddNewDiscount;
