import { EyeOutlined, MoreOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  Popover,
  Table,
  Typography,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  base_url,
  config,
  openErrorNotification,
  openWarningNotification,
} from "../helpers/headers";
import dayjs from "dayjs";
import { AddNewDiscount, DiscountDetails } from "../componants";

const columns = [
  {
    title: "Shop Name",
    dataIndex: "shopName",
    key: "0",
    sorter: (a, b) => a?.shopName?.localeCompare(b?.shopName),
    defaultSortOrder: "ascend",
  },
  {
    title: "Item Name",
    dataIndex: "itemName",
    key: "1",
    sorter: (a, b) => a?.itemName.localeCompare(b?.itemName),
    defaultSortOrder: "ascend",
  },
  {
    title: "Category",
    dataIndex: "sellerType",
    key: "2",
    sorter: (a, b) =>
      a?.sellerType.props.children.localeCompare(b?.sellerType.props.children),
    defaultSortOrder: "ascend",
  },
  {
    title: "Start Date",
    dataIndex: "startDate",
    key: "3",
    sorter: (a, b) => a?.startDate.localeCompare(b?.startDate),
    defaultSortOrder: "ascend",
  },
  {
    title: "End Date",
    dataIndex: "endDate",
    key: "4",
    sorter: (a, b) => a?.endDate.localeCompare(b?.endDate),
    defaultSortOrder: "ascend",
  },
  {
    title: "Discount %",
    dataIndex: "discount",
    key: "5",
    sorter: (a, b) => a?.discount - b?.discount,
    defaultSortOrder: "ascend",
  },
  {
    title: "Options",
    dataIndex: "options",
    key: "6",
  },
];

const rangePresets = [
  {
    label: "Last 7 Days",
    value: [dayjs().add(-7, "d"), dayjs()],
  },
  {
    label: "Last 14 Days",
    value: [dayjs().add(-14, "d"), dayjs()],
  },
  {
    label: "Last 30 Days",
    value: [dayjs().add(-30, "d"), dayjs()],
  },
  {
    label: "Last 90 Days",
    value: [dayjs().add(-90, "d"), dayjs()],
  },
];

const DiscountCentre = () => {
  const [dataLoading, setDataLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [viewDiscountDrawerVisible, setViewDiscountDrawerVisible] =
    useState(false);
  const [createDiscountDrawerVisible, setCreateDiscountDrawerVisible] =
    useState(false);
  const [selectedData, setSelectedData] = useState();
  const [dataArr, setDataArr] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const { RangePicker } = DatePicker;

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      getData(dateStrings);
    } else {
      getData([]);
    }
  };

  const handleOpenViewDiscountDrawer = (dataSet) => {
    setSelectedData(dataSet);
    setViewDiscountDrawerVisible(true);
  };
  const handleCloseViewDiscountDrawer = () => {
    setViewDiscountDrawerVisible(false);
  };
  const handleOpenCreateDiscountDrawer = () => {
    setCreateDiscountDrawerVisible(true);
  };
  const handleCloseCreateDiscountDrawer = () => {
    setCreateDiscountDrawerVisible(false);
  };

  const handleCloseRawMenu = () => {
    setMenuOpen(false);
  };
  const handleOpenRawMenu = () => {
    console.log("heloo")
    setMenuOpen(true);
  };

  const discountCategory = (type, color) => {
    return (
      <p
        className={`w-20 py-1 px-0 ${color} text-[10px] font-[600] border rounded-full text-center text-white`}
      >
        {type}
      </p>
    );
  };

  // const menuContent = (data) => (
  //   <div className="flex flex-col w-20 gap-2">
  //     <a
  //       onClick={() => {
  //         console.log("activ");
  //         handleCloseRawMenu();
  //       }}
  //     >
  //       Edit
  //     </a>
  //     <a
  //       onClick={() => {
  //         console.log("delet");
  //       }}
  //     >
  //       Deactivate/Activate
  //     </a>
  //     <a
  //       onClick={() => {
  //         console.log("edit");
  //       }}
  //     >
  //       Delete
  //     </a>
  //   </div>
  // );


  const content = (
    <div>
      <p>Content</p>
      <p>Content</p>
    </div>
  );

  useEffect(() => {
    getData([]);
  }, []);

  const getData = (date) => {
    setDataLoading(true);
    const data = {
      category_type_id: 0,
      product_type_id: 0,
      store_id: 0,
      item_id: 0,
      start_date: date[0],
      end_date: date[1],
    };

    axios
      .post(base_url + "discount/center/view", data, config)
      .then((response) => {
        if (response.data.success) {
          setDataArr(response.data.output);
          setData(response.data.output);
        } else {
          openWarningNotification(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification(error.response.data.message);
        } else if (error.response.status === 500) {
          openErrorNotification("Internal Server Error!" + error.message);
        } else {
          openErrorNotification("Error!" + error.message);
        }
      });
  };

  console.log(menuOpen)

  const setData = (dataArr) => {
    if (dataArr.lenght !== 0) {
      const tableData = [];
      dataArr.map((dataSet, id) => {
        const data = {
          key: id,
          shopName: dataSet?.store_data?.store_name,
          itemName: dataSet?.item_name,
          sellerType:
            dataSet?.category_type_id === 1 && dataSet?.product_type_id === 1
              ? discountCategory("Restaurant", "bg-restaurant_color")
              : dataSet?.category_type_id === 1 &&
                dataSet?.product_type_id === 2
              ? discountCategory("Dark Kitchen", "bg-restaurant_color")
              : dataSet?.category_type_id === 2 &&
                dataSet?.product_type_id === 0
              ? discountCategory("GROCERY", "bg-grocery_color")
              : dataSet?.category_type_id === 3 &&
                dataSet?.product_type_id === 0
              ? discountCategory("CRAFT", "bg-craft_color")
              : dataSet?.category_type_id === 4 &&
                dataSet?.product_type_id === 3
              ? discountCategory("Fish Shop", "bg-minimart_color")
              : dataSet?.category_type_id === 4 &&
                dataSet?.product_type_id === 4
              ? discountCategory("Vege Shop", "bg-minimart_color")
              : dataSet?.category_type_id === 4 &&
                dataSet?.product_type_id === 5
              ? discountCategory("Meat Shop", "bg-minimart_color")
              : "",
          startDate: dataSet?.start_date,
          endDate: dataSet?.end_date,
          discount: dataSet?.discount,
          options: (
            <div className="flex flex-row items-center gap-2">
              <a
                onClick={() => {
                  handleOpenViewDiscountDrawer(dataSet);
                }}
              >
                <EyeOutlined className="text-lg text-text_blue" />
              </a>

              <Popover
                content={content}
                trigger="click"
                open={menuOpen}
                onOpenChange={()=>{
                  handleOpenRawMenu();
                }}
              >
                <MoreOutlined               
                  className="text-xl text-text_blue"
                />
              </Popover>
            </div>
          ),
        };
        tableData.push(data);
      });
      setTableData(tableData);
    } else {
      setTableData([]);
    }
    setDataLoading(false);
  };

  const searchData = (text) => {
    setDataLoading(true);
    let filtered = dataArr?.filter((data) => {
      return (
        data.store_data?.store_name
          ?.toLowerCase()
          .includes(text.toLowerCase()) ||
        data.item_name?.toLowerCase().includes(text.toLowerCase()) ||
        data.store_location?.toLowerCase().includes(text.toLowerCase()) ||
        data.start_date?.toString().includes(text.toString()) ||
        data.end_date?.toString().includes(text.toString()) ||
        data.discount?.toString().includes(text.toString())
      );
    });
    setData(filtered);
  };

  return (
    <div className="flex flex-col items-center w-full gap-4 ">
      <div className="flex flex-row items-center w-full justify-evenly">
        <div className="flex min-w-[150px] w-auto">
          <Typography className="text-lg font-semibold text-text_blue1">
            Discount Center
          </Typography>
        </div>
        <div className="flex w-[40%]">
          <Form className="w-full">
            <div className="flex flex-row w-full items-center h-auto sm:h-[35px] rounded-2xl">
              <Input
                className="rounded-full border-border_blue border-[1px]"
                type="text"
                placeholder="Search Orders by name or ID"
                onChange={(e) => {
                  searchData(e.target.value);
                }}
                suffix={
                  <SearchOutlined className="text-base font-bold site-form-item-icon text-border_blue" />
                }
              />
            </div>
          </Form>
        </div>
        <div className="flex items-center justify-end w-[20%]">
          <RangePicker
            className="w-full rounded-2xl border-border_blue border-[1px]"
            presets={rangePresets}
            onChange={onRangeChange}
          />
        </div>
        <div className="flex w-[16%]">
          <Button
            onClick={() => {
              handleOpenCreateDiscountDrawer();
            }}
            className="w-full mb-1 bg-primary_bluedark text-primary_white rounded-xl"
          >
            Create Discount
          </Button>
        </div>
        {createDiscountDrawerVisible ? (
          <AddNewDiscount
            open={createDiscountDrawerVisible}
            onClose={handleCloseCreateDiscountDrawer}
          />
        ) : (
          <></>
        )}
      </div>

      <div className="w-full">
        <Table
          dataSource={tableData}
          columns={columns}
          loading={dataLoading}
          showSorterTooltip={false}
          pagination={{
            size: "small",
            defaultPageSize: 5,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20"],
            showQuickJumper: true,
          }}
        />
        {viewDiscountDrawerVisible ? (
          <DiscountDetails
            open={viewDiscountDrawerVisible}
            onClose={handleCloseViewDiscountDrawer}
            dataSet={selectedData}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default DiscountCentre;
