import { ConfigProvider, Image, Layout, Typography } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Sider from "antd/es/layout/Sider";
import Sidebar from "./Sidebar";
import {
  Dashboard,
  DeliveryHeroes,
  Foods,
  NewRequests,
  Orders,
  Payments,
} from "../pages";
import { Bell } from "../assets";
import { decrypt } from "../utils/Protector";
import Groceries from "../pages/Groceries";
import MiniMart from "../pages/MiniMart";
import Crafts from "../pages/Crafts";
import Settings from "../pages/Settings";
import BattaSupport from "../pages/BattaSupport";
import DiscountCentre from "../pages/DiscountCentre";

const Main = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [username, setUsername] = useState(
    decrypt(localStorage.getItem("name"))
  );

  const locale = "en";
  const [today, setDate] = React.useState(new Date()); // Save the current date to be able to trigger an update

  useEffect(() => {
    window.addEventListener(
      "resize",
      window.innerWidth < 1024 ? setCollapsed(true) : setCollapsed(false)
    );

    const timer = setInterval(() => {
      setDate(new Date());
    }, 60 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const hideSidebar = () => {
    if (window.innerWidth < 1024) {
      setCollapsed(!collapsed);
    }
  };

  const day = today.toLocaleDateString(locale, { weekday: "long" });
  const date = `${day}, ${today.getDate()} ${today.toLocaleDateString(locale, {
    month: "long",
  })}\n\n`;

  const hour = today.getHours();
  const wish = `Good ${
    (hour < 12 && "Morning") || (hour < 17 && "Afternoon") || "Evening"
  }, `;

  const time = today.toLocaleTimeString(locale, {
    hour: "numeric",
    hour12: true,
    minute: "numeric",
  });

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimaryBg: "var(--primary-yellowdark)",
          colorPrimary: "var(--text-black1)",
          colorInfo: "var(--text-black1)",
        },
      }}
    >
      <Layout className="flex flex-row w-full h-screen overflow-hidden">
        <Sider
          className={"shadow-2xl "}
          theme="light"
          trigger={null}
          style={{ background: "#061B34" }}
          width={230}
          collapsed={collapsed}
        >
          <Sidebar hide={hideSidebar} collapsed={collapsed} />
        </Sider>

        <Layout className="flex">
          <Header className="flex items-center justify-center w-full h-auto bg-primary_white sm:items-end sm:h-24">
            <div className="flex flex-row w-full h-auto mt-4 mb-4 rounded-md bg-header_yellow sm:mt-0 sm:h-10 ">
              <div className="flex items-center ml-2 lg:justify-start lg:w-full lg:ml-6">
                <Typography className="text-xs min-w-[100px] font-semibold md:text-sm">
                  Welcome! {username}, {wish}
                </Typography>
              </div>
              <div className="flex flex-row items-center gap-4 ml-6 mr-4 md:ml-10 lg:mr-6 lg:ml-0 md:gap-14 lg:justify-end lg:w-full">
                <Typography className="text-xs min-w-[45px] font-semibold md:text-sm">
                  {time}
                </Typography>
                <Typography className="text-xs min-w-[80px] font-semibold md:text-sm">
                  {date}
                </Typography>
                <div className="flex max-w-[20px]">
                  <Image
                    Link="/"
                    alt="bell"
                    src={Bell}
                    className=" max-w-[20px] w-auto md:w-full"
                  />
                </div>
              </div>
            </div>
          </Header>
          <Content className="h-full overflow-y-scroll rounded-md bg-primary_white">
            <Routes>
              <Route exact path="/" element={<Dashboard />} />
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/orders" element={<Orders />} />
              <Route exact path="/payments" element={<Payments />} />
              <Route exact path="/foods" element={<Foods />} />
              <Route exact path="/groceries" element={<Groceries />} />
              <Route exact path="/miniMart" element={<MiniMart />} />
              <Route exact path="/crafts" element={<Crafts />} />
              <Route
                exact
                path="/deliveryHeroes"
                element={<DeliveryHeroes />}
              />
              <Route exact path="/newrequests" element={<NewRequests />} />
              <Route exact path="/settings" element={<Settings />} />
              <Route exact path="/battaSupport" element={<BattaSupport />} />
              <Route
                exact
                path="/discountCentre"
                element={<DiscountCentre />}
              />
            </Routes>
          </Content>
          <Footer className="pt-0 text-center bg-footer_gray text-primary_white">
            Copyright 2023 © ALL RIGHTS RESERVED. Design by
            <a
              href="https://battadelivery.com"
              target="_blank"
              rel="noreferrer"
            >
              AVENTURE
            </a>
          </Footer>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default Main;
