import { Button, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const ResetSuccessfully = () => {
  const navigate = useNavigate();
  const Login = () => {
    navigate("/login");
  };

  return (
    <div className="flex flex-col items-center justify-center w-4/6 bg-header_yellow min-w-[300px] ml-72 h-1/2 rounded-3xl">
      <div className="flex flex-col items-center justify-center w-full gap-4">
        <Typography className="text-base font-semibold text-center text-text_blue1 lg:text-xl">
          Password Reset Successfully
        </Typography>
        <Typography className="mt-3 text-sm font-medium text-text_blacklight">
          Login Using Your New Password
        </Typography>
        <Button
          onClick={Login}
          className="w-2/4 h-8 text-sm font-semibold text-center text-primary_white bg-primary_bluedark"
        >
          LOGIN
        </Button>
      </div>
    </div>
  );
};

export default ResetSuccessfully;
