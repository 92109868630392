import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./layouts/Main";
import {
  Dashboard,
  DeliveryHeroes,
  Foods,
  Login,
  NewRequests,
  Orders,
  Payments,
  ResetPassword,
} from "./pages";
import Groceries from "./pages/Groceries";
import MiniMart from "./pages/MiniMart";
import Crafts from "./pages/Crafts";
import Settings from "./pages/Settings";
import BattaSupport from "./pages/BattaSupport";
import DiscountCentre from "./pages/DiscountCentre";

function App() {
  const token = sessionStorage.getItem("token");

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/resetpassword" element={<ResetPassword />} />

        {token !== null ? (
          <Route path="/" element={<Main />}>
            <Route exact path="/" element={<Dashboard />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/orders" element={<Orders />} />
            <Route exact path="/payments" element={<Payments />} />
            <Route exact path="/foods" element={<Foods />} />
            <Route exact path="/groceries" element={<Groceries />} />
            <Route exact path="/miniMart" element={<MiniMart />} />
            <Route exact path="/crafts" element={<Crafts />} />
            <Route exact path="/deliveryHeroes" element={<DeliveryHeroes />} />
            <Route exact path="/newrequests" element={<NewRequests />} />
            <Route exact path="/settings" element={<Settings />} />
            <Route exact path="/battaSupport" element={<BattaSupport />} />
            <Route exact path="/discountCentre" element={<DiscountCentre />} />
          </Route>
        ) : (
          <>
            <Route exact path="*" element={<Login />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
