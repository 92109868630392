import React from 'react'

const BattaSupport = () => {
  return (
    <div>
      BattaSupport
    </div>
  )
}

export default BattaSupport
