import { Col, Divider, Drawer, Row, Typography } from "antd";
import React from "react";
import { Food1 } from "../../assets";

const viewDeliveryHeroes = ({ open, onClose, dataSet }) => {
  return (
    <>
      <Drawer
        title={`View DeliveryHeroes`}
        placement="right"
        size={"large"}
        onClose={onClose}
        open={open}
      >
        <Col className="flex flex-col items-center justify-center">
          <img src={Food1} alt="Food 1" className="w-32 h-32 rounded-full" />
          <Typography className="mt-2 text-2xl font-bold ">
            {dataSet?.hero_name}
          </Typography>
          <Typography className="mt-2 text-base font-medium">
            <span>Reg No: </span>
            {dataSet?.reg_number}
          </Typography>
        </Col>
        <Divider />
        <div className="flex flex-row items-center justify-center w-full gap-2">
          <Col className="flex flex-col items-end gap-3">
            <Typography className="text-base font-medium">
              Created At :
            </Typography>
            <Typography className="text-base font-medium">
              Phone Number :
            </Typography>
            <Typography className="text-base font-medium">Address :</Typography>
            <Typography className="text-base font-medium">
              Vehicle Type :
            </Typography>
            <Typography className="text-base font-medium">
              Description :
            </Typography>
            <Typography className="text-base font-medium ">
              Total Sale :
            </Typography>
          </Col>

          <Col className="flex flex-col items-start gap-3">
            <Typography className="text-base">
              {dataSet?.created_at ? dataSet?.created_at : "-"}
            </Typography>
            <Typography className="text-base">
              {dataSet?.phone_number ? dataSet?.phone_number : "-"}
            </Typography>
            <Typography className="text-base">
              {dataSet?.address ? dataSet?.address : ""}
            </Typography>
            <Typography className="text-base">
              {dataSet?.vehicle_type ? dataSet?.vehicle_type : "-"}
            </Typography>
            <Typography className="text-base">
              {dataSet?.vehicle_type_description
                ? dataSet?.vehicle_type_description
                : "-"}
            </Typography>
            <Typography className="text-base">
              {dataSet?.total_deliveries ? dataSet?.total_deliveries : "-"}
            </Typography>
          </Col>
        </div>
      </Drawer>
    </>
  );
};

export default viewDeliveryHeroes;
