import { Form, Input, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import "../styles/NewRequests.css";
import axios from "axios";
import { ViewRequests } from "../componants";
import {
  base_url,
  config,
  openErrorNotification,
  openWarningNotification,
} from "../helpers/headers";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "0",
    sorter: (a, b) => a.name.localeCompare(b.name),
    defaultSortOrder: "ascend",
  },
  {
    title: "Request No",
    dataIndex: "requestNo",
    key: "1",
    sorter: (a, b) => a.requestNo.localeCompare(b.requestNo),
    defaultSortOrder: "ascend",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "2",
    sorter: (a, b) => a.date.localeCompare(b.date),
    defaultSortOrder: "ascend",
  },
  {
    title: "Request Type",
    dataIndex: "requestType",
    key: "3",
    sorter: (a, b) =>
      a.requestType.props.children.localeCompare(b.requestType.props.children),
    defaultSortOrder: "ascend",
  },
  {
    title: "Address / Location",
    dataIndex: "location",
    key: "4",
    sorter: (a, b) => a.location.localeCompare(b.location),
    defaultSortOrder: "ascend",
  },
  {
    title: "Contact No",
    dataIndex: "contactNo",
    key: "5",
    sorter: (a, b) => a.contactNo - b.contactNo,
    defaultSortOrder: "ascend",
  },
  {
    title: "Options",
    dataIndex: "options",
    key: "6",
  },
];

const NewRequests = () => {
  const [tableData, setTableData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [isOpenDataViewDrawer, setIsOpenDataViewDrawer] = useState(false);
  const [dataArr, setDataArr] = useState([]);

  useEffect(() => {
    loadRequests(0, -1);
  }, []);

  const handleDataViewDrawer = (dataSet) => {
    setSelectedData(dataSet);
    setIsOpenDataViewDrawer(true);
  };

  const closeDrawer = () => {
    setIsOpenDataViewDrawer(false);
  };

  const requestTypeStat = (type, color, width) => {
    return (
      <p
        className={`${width} py-1 px-0 ${color} text-[10px] font-[600] border rounded-full text-center text-white`}
      >
        {type}
      </p>
    );
  };

  const loadRequests = (state, sellerType) => {
    setDataLoading(true);
    const data = {
      status: state,
      seller_type: sellerType,
    };

    axios
      .post(base_url + "view/all", data, config)
      .then((response) => {
        if (response.data.success) {
          setData(response.data.output);
          setDataArr(response.data.output);
        } else {
          openWarningNotification(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification(error.response.data.message);
        } else if (error.response.status === 500) {
          openErrorNotification("Internal Server Error!" + error.message);
        } else {
          openErrorNotification("Error!" + error.message);
        }
      });
  };

  const setData = (dataArr) => {
    if (dataArr.length !== 0) {
      let tableData = [];
      dataArr.forEach((dataSet, x) => {
        let data = {
          key: x,
          name: dataSet?.store_name || dataSet?.hero_name,
          requestNo: dataSet?.reg_number,
          date: dataSet?.created_at,
          requestType:
            dataSet?.seller_type === "Restaurant"
              ? requestTypeStat("Restaurant", "bg-restaurant_color", "w-4/5")
              : dataSet?.seller_type === "Dark Kitchen"
              ? requestTypeStat("Dark Kitchen", "bg-restaurant_color", "w-4/5")
              : dataSet?.seller_type === "Meat Shop"
              ? requestTypeStat("Meat Shop", "bg-minimart_color", "w-4/5")
              : dataSet?.seller_type === "Fish Shop"
              ? requestTypeStat("Fish Shop", "bg-minimart_color", "w-4/5")
              : dataSet?.seller_type === "Vege Shop"
              ? requestTypeStat("Vege Shop", "bg-minimart_color", "w-4/5")
              : dataSet?.seller_type === "Groceries"
              ? requestTypeStat("GROCERY", "bg-grocery_color", "w-4/5")
              : dataSet?.seller_type === "Crafts"
              ? requestTypeStat("CRAFT", "bg-craft_color", "w-4/5")
              : dataSet?.seller_type === "Delivery hero"
              ? requestTypeStat(
                  "DELIVERY HERO",
                  "bg-deliveryhero_color",
                  "w-4/5"
                )
              : "",

          location: dataSet?.address,
          contactNo: dataSet?.phone_number,
          options: (
            <div className="flex flex-row gap-4 text-2xl text-primaryLight">
              <a
                onClick={() => {
                  handleDataViewDrawer(dataSet);
                }}
              >
                <EyeOutlined style={{ fontSize: "18px" }} />
              </a>
            </div>
          ),
        };
        tableData.push(data);
      });
      setTableData(tableData);

    } else {
      setTableData([]);
    }
    setDataLoading(false);
  };
  const searchData = (text) => {
    setDataLoading(true);
    let filtered = dataArr?.filter((data) => {
      return (
        (data.store_name || data.hero_name)
          .toLowerCase()
          .includes(text.toLowerCase()) ||
        data.reg_number.toLowerCase().includes(text.toLowerCase()) ||
        data.created_at.toLowerCase().includes(text.toLowerCase()) ||
        data.seller_type.toLowerCase().includes(text.toLowerCase()) ||
        data.address.toLowerCase().includes(text.toLowerCase()) ||
        data.phone_number.toLowerCase().includes(text.toLowerCase())
      );
    });
    setData(filtered);
  };

  return (
    <div className="flex flex-col w-full gap-3 mt-3">
      <div className="flex flex-row items-center w-full justify-evenly">
        <Typography className="text-base flex justify-start sm:text-lg w-auto  min-w-[140px] font-semibold text-text_blue1">
          New Join Requests
        </Typography>

        <Form className="flex sm:justify-end w-auto lg:w-[70%] ">
          <div className="flex flex-row md:w-1/2 items-center h-auto sm:h-[35px] rounded-2xl">
            <Input
              className="rounded-full border-border_blue border-[1px]"
              type="text"
              placeholder="Search Join Requests by name or ID"
              onChange={(e) => {
                searchData(e.target.value);
              }}
              suffix={
                <SearchOutlined className="text-base font-bold site-form-item-icon text-border_blue" />
              }
            />
          </div>
        </Form>
      </div>

      {/* contents*/}
      <div className="relative">
        <div className="w-full">
          <Table
            dataSource={tableData}
            columns={columns}
            loading={dataLoading}
            showSorterTooltip={false}
            pagination={{
              size: "small",
              defaultPageSize: 5,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "20"],
              showQuickJumper: true,
            }}
          />
          {isOpenDataViewDrawer ? (
            <ViewRequests
              open={isOpenDataViewDrawer}
              onClose={closeDrawer}
              dataSet={selectedData}
              loadRequests={loadRequests}
              requestTypeStat={requestTypeStat}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewRequests;
