import { message } from "antd";

export const base_url = "https://api.battadelivery.com/api/";
export const token = sessionStorage.getItem("token");

const api = message;

export const config = {
  headers: { Authorization: `Bearer ${token}` },
};

export const openSuccessNotification = (description) => {
  api.success(description);
};

export const openErrorNotification = (description) => {
  api.error(description);
};

export const openWarningNotification = (description) => {
  api.warning(description);
};
