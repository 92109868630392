import {Col, Divider, Drawer, Row} from "antd";
import {Food1} from "../../assets";

function ViewFoods({open, onClose, dataSet}) {
    return (
        <>
            <Drawer
                title={`View Restaurant`}
                placement="right"
                size={"large"}
                onClose={onClose}
                open={open}
            >
                <Row className='flex flex-col items-center justify-center'>
                    <img src={Food1} alt="Food 1" className="w-2/5 h-auto rounded-full"/>
                    <p className="pt-6 text-2xl font-bold">{dataSet?.store_name}</p>
                    <br/>
                    <p className="text-base font-medium"><span>Reg No: </span>{dataSet?.reg_number}</p>
                </Row>
                <Divider/>
                <Row className='sm:w-full'>
                    <Col span={6} offset={6} className="pl-10">
                        <p className="text-base">Location</p>
                        <Divider/>
                        <p className="text-base">Total Sale</p>
                    </Col>
                    <Col span={12}>
                        <p className="text-base font-medium">{dataSet?.address}</p>
                        <Divider/>
                        <p className="text-base font-medium">{dataSet?.total_sales}</p>
                    </Col>
                </Row>
            </Drawer>
        </>
    );
}

export default ViewFoods;