import {
  Button,
  Divider,
  Drawer,
  Modal,
  Popconfirm,
  Row,
  Typography,
} from "antd";
import axios from "axios";
import React, { useState } from "react";
import {
  base_url,
  config,
  openErrorNotification,
  openSuccessNotification,
  openWarningNotification,
} from "../../helpers/headers";

const ViewRequests = ({
  open,
  onClose,
  dataSet,
  loadRequests,
  requestTypeStat,
}) => {
  const [visibleRejectConfirm, setVisibleRejectConfirm] = useState(false);
  const [visibleAcceptConfirm, setVisibleAcceptConfirm] = useState(false);

  const handleRequest = (requestID, status) => {
    const data = {
      request_id: requestID,
      type_id:
        dataSet?.seller_type === "Restaurant"
          ? 1
          : dataSet?.seller_type === "Dark Kitchen"
          ? 2
          : dataSet?.seller_type === "Groceries"
          ? 3
          : dataSet?.seller_type === "Crafts"
          ? 4
          : dataSet?.seller_type === "Vege Shop"
          ? 5
          : dataSet?.seller_type === "Fish Shop"
          ? 6
          : dataSet?.seller_type === "Meat Shop"
          ? 7
          : dataSet?.seller_type === "Delivery hero"
          ? 8
          : "",
      status: status,
    };

    axios
      .post(base_url + "approve/request", data, config)
      .then((response) => {
        if (response.data.success) {
          openSuccessNotification(response.data.message);
          loadRequests(0, -1);
        } else {
          openWarningNotification(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification("Error!" + error.response.data.message);
        } else if (error.response.status === 500) {
          openErrorNotification("Internal Server Error!" + error.message);
        } else {
          openErrorNotification("Error!" + error.message);
        }
      });
  };

  const handleOpenRejectConfirm = () => {
    setVisibleRejectConfirm(true);
  };
  const handleCloseRejectConfirm = () => {
    setVisibleRejectConfirm(false);
  };

  const rejectConfirm = () => {
    handleRequest(dataSet?.store_id || dataSet?.hero_id, 2);
    onClose();
    handleCloseRejectConfirm();
  };

  const handleOpenAcceptConfirm = () => {
    setVisibleAcceptConfirm(true);
  };
  const handleCloseAcceptConfirm = () => {
    setVisibleAcceptConfirm(false);
  };

  const acceptConfirm = () => {
    handleRequest(dataSet?.store_id || dataSet?.hero_id, 1);
    onClose();
    handleCloseAcceptConfirm();
  };

  return (
    <>
      <Drawer
        title={`Join Request Id : ${dataSet?.reg_number} `}
        placement="right"
        size={"large"}
        onClose={onClose}
        open={open}
        className="text-center"
      >
        <Row className="flex flex-col items-center justify-center">
          <Typography className="mb-2 text-base font-medium">
            Requested on: {dataSet.created_at}
          </Typography>
          {dataSet?.seller_type === "Restaurant"
            ? requestTypeStat("Restaurant", "bg-restaurant_color", "w-40")
            : dataSet?.seller_type === "Dark Kitchen"
            ? requestTypeStat("Dark Kitchen", "bg-restaurant_color", "w-40")
            : dataSet?.seller_type === "Meat Shop"
            ? requestTypeStat("Meat Shop", "bg-minimart_color", "w-40")
            : dataSet?.seller_type === "Fish Shop"
            ? requestTypeStat("Fish Shop", "bg-minimart_color", "w-40")
            : dataSet?.seller_type === "Vege Shop"
            ? requestTypeStat("Vege Shop", "bg-minimart_color", "w-40")
            : dataSet?.seller_type === "Groceries"
            ? requestTypeStat("GROCERY", "bg-grocery_color", "w-40")
            : dataSet?.seller_type === "Crafts"
            ? requestTypeStat("CRAFT", "bg-craft_color", "w-40")
            : dataSet?.seller_type === "Delivery hero"
            ? requestTypeStat("DELIVERY HERO", "bg-deliveryhero_color", "w-40")
            : ""}
        </Row>
        <Row className="flex flex-col gap-4 mt-4">
          <div className="flex flex-col">
            <span className="text-base font-semibold text-text_blue1">
              {dataSet?.store_name ? "Restaurant Name" : "Delivery Hero Name:"}
            </span>
            <span className="text-lg font-semibold text-text_blacklight">
              {dataSet?.store_name || dataSet?.hero_name}
            </span>
          </div>

          <div className="flex flex-col">
            <span className="text-base font-semibold text-text_blue1">
              Owner’s Name
            </span>
            <span className="text-lg font-semibold text-text_blacklight">
              {dataSet?.owners_name || dataSet?.hero_name}
            </span>
          </div>

          <div className="flex flex-col">
            <span className="text-base font-semibold text-text_blue1">
              Contact Number
            </span>
            <span className="text-lg font-semibold text-text_blacklight">
              {dataSet?.phone_number}
            </span>
          </div>

          <div className="flex flex-col">
            <span className="text-base font-semibold text-text_blue1">
              e-Mail
            </span>
            <span className="text-lg font-semibold text-text_blacklight">
              {dataSet?.email_address}
            </span>
          </div>

          <div className="flex flex-col">
            <span className="text-base font-semibold text-text_blue1">
              Address
            </span>
            <span className="text-lg font-semibold text-text_blacklight">
              {dataSet.address}
            </span>
          </div>
        </Row>
        <Divider className="mt-6" />
        <Row className="flex-row justify-center w-full gap-6">
          <Button
            onClick={() => {
              handleOpenRejectConfirm();
            }}
            className="text-base font-semibold text-text_black2 w-44 rounded-3xl bg-border_graydark2"
          >
            Reject
          </Button>
          <Modal
            title={
              <Typography className="flex ml-6 text-base font-semibold text-center text-primary_red">
                Are you sure you want to Reject this Request?
              </Typography>
            }
            open={visibleRejectConfirm}
            onCancel={handleCloseRejectConfirm}
            centered
            style={{ top: 80 }}
            width={450}
            maskClosable={false}
            footer={[
              <div className="flex flex-row justify-center gap-3">
                <Button
                  key="reject"
                  onClick={() => {
                    rejectConfirm();
                  }}
                  className="w-32 text-base font-medium text-center border-2 rounded-3xl border-primary_red text-primary_red"
                >
                  Reject
                </Button>

                <Button
                  key="close1"
                  onClick={() => {
                    handleCloseRejectConfirm();
                  }}
                  className="w-32 text-base font-medium text-center rounded-3xl text-primary_white bg-primary_bluedark"
                >
                  Close
                </Button>
              </div>,
            ]}
          >
            <Typography className="flex text-sm font-normal text-center text-text_blacklight">
              If rejected, this request will be removed from the Batta Delivery
              database.
            </Typography>
          </Modal>
          <Button
            onClick={() => {
              handleOpenAcceptConfirm();
            }}
            className="text-base font-semibold text-primary_white w-44 rounded-3xl bg-primary_bluedark"
          >
            Accept
          </Button>
          <Modal
            title={
              <Typography className="flex ml-6 text-base font-semibold text-center text-text_black2">
                Are you sure you want to accept this Request?
              </Typography>
            }
            open={visibleAcceptConfirm}
            onCancel={handleCloseAcceptConfirm}
            centered
            style={{ top: 80 }}
            width={450}
            maskClosable={false}
            footer={[
              <div className="flex flex-row justify-center gap-3">
                <Button
                  key="close"
                  onClick={() => {
                    handleCloseAcceptConfirm();
                  }}
                  className="w-32 text-base font-medium text-center bg-border_graydark rounded-3xl text-text_black2"
                >
                  Close
                </Button>

                <Button
                  key="accept"
                  onClick={() => {
                    acceptConfirm();
                  }}
                  className="w-32 text-base font-medium text-center rounded-3xl text-primary_white bg-primary_bluedark"
                >
                  Accept
                </Button>
              </div>,
            ]}
          >
            <Typography className="flex text-sm font-normal text-center text-text_blacklight">
              Once approved, the user will receive an email invitation
              containing a link to download the app.
            </Typography>
          </Modal>
          <Button className="text-base font-semibold w-44 text-text_black2 rounded-3xl bg-primary_yellowdark">
            View On map
          </Button>
        </Row>
      </Drawer>
    </>
  );
};

export default ViewRequests;
