import { Button, Typography } from "antd";
import React from "react";

const DashboardStatLeft = ({ img, fname, rname, sname, pid }) => {
  return (
    <div className="flex flex-row pb-3 border-b border-border_graydark">
      <img src={img} alt="food" className="flex w-16 h-16" />
      <div className="flex flex-col ml-2">
        <Typography className="text-base font-semibold text-text_black2">
          {fname}
        </Typography>
        <Typography className="text-sm font-normal text-text_blue1">
          {rname} / {sname}
        </Typography>
        <Typography className="text-sm font-semibold text-text_blacklight">
          Product ID {pid}
        </Typography>
      </div>
      <div className="flex flex-row items-end justify-end w-1/2 gap-6">
        <Button className="w-20 bg-primary_greendark rounded-3xl text-primary_white">
          34
        </Button>
        <Button className="w-20 bg-primary_bluedark rounded-3xl text-primary_white">
          1920
        </Button>
      </div>
    </div>
  );
};

export default DashboardStatLeft;
