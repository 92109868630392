import { Button, Card, Drawer, Typography } from "antd";
import React, { useState } from "react";
import ViewOrder from "./ViewOrder";

const RecentPickupRequests = ({ dataArr }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleOpenDrawer = () => {
    setDrawerVisible(true);
  };
  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };

  return (
    <Card className="flex w-56 shadow-2xl border-border_gray h-52 rounded-3xl">
      <div className="flex flex-col gap-2">
        <div className="flex flex-row w-full gap-8 ">
          <Typography className="text-xs font-semibold text-text_blacklight">
            Order No :{dataArr?.order_number}
          </Typography>
          <Typography className="justify-end text-xs text-text_green">
            {dataArr?.created_at}
          </Typography>
        </div>
        <div className="flex justify-center w-full ">
          <Typography className="text-sm font-semibold text-primary_yellowdark">
            Restaurant: {dataArr?.store_name}
          </Typography>
        </div>
        <div className="flex flex-col w-full">
          <Typography className="text-xs text-text_blue1">From:</Typography>
          <Typography className="text-xs text-text_blue1">
            {dataArr?.store_location}
          </Typography>
        </div>
        <div className="flex flex-col w-full">
          <Typography className="text-xs text-text_blue1">To:</Typography>
          <Typography className="text-xs text-text_blue1">
            {dataArr?.delivery_address}
          </Typography>
        </div>
        <div className="flex flex-row w-full gap-4">
          <Button className="w-20 h-8 text-xs bg-border_graydark rounded-2xl">
            {"10 Min"}
          </Button>
          <Button
            className="w-20 h-8 text-xs bg-primary_yellowdark rounded-2xl"
            onClick={() => {
              handleOpenDrawer();
            }}
          >
            View
          </Button>

          <ViewOrder
            open={drawerVisible}
            onClose={handleCloseDrawer}
            dataArr={dataArr}
          />
        </div>
      </div>
    </Card>
  );
};

export default RecentPickupRequests;
