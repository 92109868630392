import { useEffect, useState } from "react";
import "../styles/Foods.css";
import { Form, Input, Table } from "antd";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { ViewFoods } from "../componants";
import axios from "axios";
import {
  base_url,
  config,
  openErrorNotification,
  openWarningNotification,
} from "../helpers/headers";

const columns = [
  {
    title: "Restaurant Name",
    dataIndex: "restaurantName",
    key: "0",
    sorter: (a, b) => a.restaurantName.localeCompare(b.restaurantName),
    defaultSortOrder: "ascend",
  },
  {
    title: "Reg No",
    dataIndex: "regNo",
    key: "1",
    sorter: (a, b) => a.regNo.localeCompare(b.regNo),
    defaultSortOrder: "ascend",
  },
  {
    title: "Seller Type",
    dataIndex: "sellerType",
    key: "2",
    sorter: (a, b) =>
      a.sellerType.props.children.localeCompare(b.sellerType.props.children),
    defaultSortOrder: "ascend",
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "3",
    sorter: (a, b) => a.location.localeCompare(b.location),
    defaultSortOrder: "ascend",
  },
  {
    title: "Total Sales",
    dataIndex: "totalSales",
    key: "4",
    sorter: (a, b) => a.totalSales - b.totalSales,
    defaultSortOrder: "ascend",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "5",
  },
];

const Foods = () => {
  const [tableData, setTableData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [isOpenDataViewDrawer, setIsOpenDataViewDrawer] = useState(false);
  const [dataArr, setDataArr] = useState([]);

  const handleDataViewDrawer = (dataSet) => {
    setSelectedData(dataSet);
    setIsOpenDataViewDrawer(true);
  };

  const closeDrawer = () => {
    setIsOpenDataViewDrawer(false);
  };

  useEffect(() => {
    loadFoods(1, 1);
  }, []);

  const loadFoods = (status, sellerType) => {
    setDataLoading(true);
    const data = {
      status: status,
      seller_type: sellerType,
    };

    axios
      .post(base_url + "view/all", data, config)
      .then((response) => {
        if (response.data.success) {
          setData(response.data.output);
          setDataArr(response.data.output);
        } else {
          openWarningNotification(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification("Error!", error.response.data.message);
        } else if (error.response.status === 500) {
          openErrorNotification("Internal Server Error!", error.message);
        } else {
          openErrorNotification("Error!", error.message);
        }
      });
  };

  const setData = (dataArr) => {
    if (dataArr.length !== 0) {
      let tableData = [];
      dataArr.forEach((dataSet, x) => {
        let data = {
          key: x,
          restaurantName: dataSet?.store_name,
          regNo: dataSet?.reg_number,
          sellerType:
            dataSet?.seller_type === "Restaurant" ? (
              <div className="w-3/5 py-1 px-0 bg-restaurant_color text-[10px] font-[600] border rounded-full text-center text-white">
                RESTAURANT
              </div>
            ) : dataSet?.seller_type === "Dark Kitchen" ? (
              <div className="w-3/5 py-1 px-0 bg-darkkitchen_color text-[10px] font-[600] border rounded-full text-center text-white">
                DARK KITCHEN
              </div>
            ) : (
              ""
            ),
          location: dataSet?.address,
          totalSales: dataSet?.total_sales,
          action: (
            <div className="flex flex-row gap-4 text-2xl text-primaryLight">
              <a
                onClick={() => {
                  handleDataViewDrawer(dataSet);
                }}
              >
                <EyeOutlined style={{ fontSize: "18px" }} />
              </a>
            </div>
          ),
        };
        tableData.push(data);
      });
      setTableData(tableData);

    } else {
      setTableData([]);
    }
    setDataLoading(false);
  };

  const searchData = (text) => {
    setDataLoading(true);
    let filtered = dataArr.filter((data) => {
      console.log(data);
      return (
        data.store_name.toLowerCase().includes(text.toLowerCase()) ||
        data.reg_number.toLowerCase().includes(text.toLowerCase()) ||
        data.seller_type.toLowerCase().includes(text.toLowerCase()) ||
        data.address.toLowerCase().includes(text.toLowerCase()) ||
        data.total_sales.toString().includes(text.toString())
      );
    });
    setData(filtered);
  };

  return (
    <div className="flex flex-col w-full gap-3 mt-3">
      <div className="flex flex-row items-center w-full">
        <Form className="flex items-center sm:justify-start w-auto lg:w-[70%] ">
          <div className="flex flex-row md:w-1/2 items-center h-auto sm:h-[35px] rounded-2xl">
            <Input
              className="rounded-full border-border_blue border-[1px]"
              type="text"
              placeholder="Search Restaurant by name or ID"
              onChange={(e) => {
                searchData(e.target.value);
              }}
              suffix={
                <SearchOutlined className="text-base font-bold site-form-item-icon text-border_blue" />
              }
            />
          </div>
        </Form>
      </div>
      <div className="relative">
        <div className="w-full">
          <Table
            dataSource={tableData}
            columns={columns}
            loading={dataLoading}
            showSorterTooltip={false}
            pagination={{
              size: "small",
              defaultPageSize: 5,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "20"],
              showQuickJumper: true,
            }}
          />
          {isOpenDataViewDrawer ? (
            <ViewFoods
              open={isOpenDataViewDrawer}
              onClose={closeDrawer}
              dataSet={selectedData}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Foods;
