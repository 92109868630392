import { Button, Form, Input, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  base_url,
  config,
  openErrorNotification,
  openSuccessNotification,
  openWarningNotification,
} from "../../helpers/headers";

const ResetAdminPassword = ({ setFormChanger, email }) => {
  const changePassword = (formData) => {
    const data = {
      email: email,
      password: formData.newpassword,
    };

    const config = config;

    axios
      .post(base_url + "reset/admin/pa", data, config)
      .then((response) => {
        if (
          response.data.success &&
          response.data.message === "Password changed."
        ) {
          openSuccessNotification(response.data.message);
          setFormChanger(0);
        } else {
          openWarningNotification(response.data.message);
          setFormChanger(0);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification("Error!" + error.response.data.message);
        } else if (error.response.status === 500) {
          openErrorNotification("Internal Server Error!" + error.message);
        } else {
          openErrorNotification("Error!" + error.message);
        }
      });
  };

  return (
    <div className="flex flex-col items-center justify-center w-3/6 min-w-[300px] bg-primary_white2 h-1/2 rounded-3xl">
      <Typography className="mt-2 text-xl font-semibold text-text_blue1 ">
        Reset your Password
      </Typography>
      <div className="flex items-center justify-center w-full">
        <Form
          name="password_reset"
          className="flex flex-col w-4/6 mt-6"
          initialValues={{
            remember: false,
          }}
          onFinish={changePassword}
        >
          <Typography className="text-sm font-medium text-text_blacklight">
            Current Password
          </Typography>
          <Form.Item
            name="currentpassword"
            rules={[
              {
                required: true,
                message: "Enter your current password!",
              },
            ]}
          >
            <Input.Password
              prefix={<UserOutlined />}
              type="password"
              placeholder="Current Password"
              className="text-sm font-light text-text_blacklight bg-primary_white"
            />
          </Form.Item>
          <Typography className="text-sm font-medium text-text_blacklight">
            New Password
          </Typography>
          <Form.Item
            name="newpassword"
            dependencies={["confirmpassword"]}
            rules={[
              {
                required: true,
                message: "Enter your new password!",
              },
              { min: 8, message: "Password must have a minimum length of 8" },
              // {
              //   pattern: new RegExp(
              //     "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
              //   ),
              //   message:
              //     "Password must contain at least one lowercase letter, uppercase letter, number, and special character",
              // },
            ]}
          >
            <Input.Password
              prefix={<UserOutlined />}
              type="password"
              placeholder="New Password"
              className="text-sm font-light text-text_blacklight bg-primary_white"
            />
          </Form.Item>
          <Typography className="text-sm font-medium text-text_blacklight">
            Confirm Password
          </Typography>
          <Form.Item
            name="confirmpassword"
            dependencies={["newpassword"]}
            rules={[
              {
                required: true,
                message: "Confirm your new password!",
              },

              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newpassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The new password that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<UserOutlined />}
              type=""
              placeholder="Confirm Password"
              className="text-sm font-light text-text_blacklight bg-primary_white"
            />
          </Form.Item>
          <div className="flex flex-row items-center w-full justify-evenly ">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="h-8 text-sm font-semibold text-center rounded-3xl w-36 text-primary_white bg-primary_bluedark"
              >
                Reset Password
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="default"
                onClick={() => {
                  setFormChanger(0);
                }}
                className="h-8 text-sm font-semibold text-center w-36 rounded-3xl bg-primary_white "
              >
                Close
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ResetAdminPassword;
