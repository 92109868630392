import CryptoJS from "crypto-js";

const secret = "LhSDkjslhdfSDH#";

export const encrypt = (text) => {
  return CryptoJS.AES.encrypt(JSON.stringify(text), secret).toString();
};

export const decrypt = (text) => {
  try {
    let byts = CryptoJS.AES.decrypt(text, secret);
    let decData = JSON.parse(byts.toString(CryptoJS.enc.Utf8));
    return decData;
  } catch (err) {
    console.log(err)
    console.log("Manipulated Session!")
  }
};
