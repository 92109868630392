import React, { useState } from "react";
import { Login_left, Login_logo, Login_right } from "../assets";
import { EnterNewPassword, ResetSuccessfully, VerifyOTP,VerifyEmail} from "../componants";

const ResetPassword = () => {
  const [formChanger, setFormChanger] = useState(0);
  const [email, setEmail] = useState("");

  return (
    <div className="flex flex-row w-full">
      <div className="flex w-full">
        <div className="relative flex justify-start w-full">
          <img src={Login_left} alt="left" className="w-full h-screen " />
        </div>
        <div className="absolute flex items-center justify-center w-1/2 h-full">
          <img
            src={Login_logo}
            alt="loginlogo"
            className="flex max-w-[450px] w-auto max-h-[250px] h-auto "
          />
        </div>
      </div>
      <div className="flex w-full -ml-60">
        <div className="relative flex justify-end w-full">
          <img src={Login_right} alt="right" className="w-full h-screen" />
        </div>
        <div className="absolute flex items-center justify-center w-1/2 h-full ">
          {formChanger === 0 ? (
            <VerifyEmail setFormChanger={setFormChanger} setEmail={setEmail} />
          ) : formChanger === 1 ? (
            <VerifyOTP setFormChanger={setFormChanger} email={email} />
          ) : formChanger === 2 ? (
            <EnterNewPassword setFormChanger={setFormChanger} email={email} />
          ) : (
            <ResetSuccessfully />
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
