import React, { useEffect, useState } from "react";
import {
  Logo,
  Dashboard,
  Orders,
  Payments,
  Requests,
  Seller,
  Setting,
  Deliveryhero,
} from "../assets";
import { Link } from "react-router-dom";
import { Menu } from "antd";

const Sidebar = () => {
  let path = window.location.pathname;
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setCollapsed(true);
    }
  });

  const selectedKey = () => {
    if (path === "/") {
      return "1";
    } else if (path === "/dashboard") {
      return "1";
    } else if (path === "/orders") {
      return "2";
    } else if (path === "/payments") {
      return "3";
    } else if (path === "/foods") {
      return "5";
    } else if (path === "/groceries") {
      return "6";
    } else if (path === "/miniMart") {
      return "7";
    } else if (path === "/crafts") {
      return "8";
    } else if (path === "/deliveryheroes") {
      return "9";
    } else if (path === "/newrequests") {
      return "10";
    } else if (path === "/settings") {
      return "11";
    } else if (path === "/battaSupport") {
      return "12";
    } else if (path === "/discountCentre") {
      return "13";
    } else if (path === "/login") {
      return "14";
    }
  };

  const userItems = [
    {
      key: "1",
      icon: <img src={Dashboard} width={21} height={18} />,
      label: (
        <Link to="/dashboard" className="font-semibold">
          Dashboard
        </Link>
      ),
    },
    {
      key: "2",
      icon: <img src={Orders} width={18} height={18} />,
      label: (
        <Link to="/orders" className="font-semibold">
          Orders
        </Link>
      ),
    },
    {
      key: "3",
      icon: <img src={Payments} width={22} height={18} />,
      label: (
        <Link to="/payments" className="font-semibold">
          Payments
        </Link>
      ),
    },
    {
      key: "4",
      icon: <img src={Seller} width={21} height={18} />,
      label: "Sellers",
      children: [
        {
          key: "5",
          label: (
            <Link to="/foods" className="font-normal">
              Foods
            </Link>
          ),
        },
        {
          key: "6",
          label: (
            <Link to="/groceries" className="font-normal">
              Groceries
            </Link>
          ),
        },
        {
          key: "7",
          label: (
            <Link to="/miniMart" className="font-normal">
              Mini Mart
            </Link>
          ),
        },
        {
          key: "8",
          label: (
            <Link to="/crafts" className="font-normal">
              Crafts
            </Link>
          ),
        },
      ],
    },
    {
      key: "9",
      icon: <img src={Deliveryhero} width={21} height={18} />,
      label: (
        <Link to="/deliveryheroes" className="font-semibold">
          Delivery Heroes
        </Link>
      ),
    },
    {
      key: "10",
      icon: <img src={Requests} width={21} height={18} />,
      label: (
        <Link to="/newrequests" className="font-semibold">
          New Requests
        </Link>
      ),
    },
    {
      key: "11",
      icon: <img src={Setting} width={21} height={18} />,
      label: (
        <Link to="/settings" className="font-semibold">
          Settings
        </Link>
      ),
    },
    {
      key: "12",
      icon: "  ",
      label: (
        <Link to="/battaSupport" className="font-semibold ml-7">
          Batta Support
        </Link>
      ),
    },
    {
      key: "13",
      icon: "",
      label: (
        <Link to="/discountCentre" className="font-semibold ml-7">
          Discount Centre
        </Link>
      ),
    },
    {
      key: "14",
      icon: "",
      label: (
        <Link
          to="/login"
          onClick={() => {
            localStorage.clear();
            sessionStorage.clear();
          }}
          className="font-semibold ml-7"
        >
          Log Out
        </Link>
      ),
    },
  ];

  return (
    <>
      <div className="flex items-center justify-center w-full bg-primary_white sm:h-32 ">
        <img src={collapsed ? Logo : Logo} alt="logo" className="w-32 h-14" />
      </div>

      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={selectedKey}
        className="h-full overflow-auto text-sm font-semibold text-text_black1 bg-primary_white"
        items={userItems}
      />
    </>
  );
};

export default Sidebar;
