import { Divider, Drawer, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";

const columns = [
  {
    title: "Item Name",
    dataIndex: "itemName",
    key: "0",
    sorter: (a, b) => a.itemName.localeCompare(b.itemName),
    defaultSortOrder: "ascend",
  },
  {
    title: "Size",
    dataIndex: "size",
    key: "1",
    sorter: (a, b) => a.size.localeCompare(b.size),
    defaultSortOrder: "ascend",
  },
  {
    title: "QTY",
    dataIndex: "qty",
    key: "2",
    sorter: (a, b) => a.qty.localeCompare(b.qty),
    defaultSortOrder: "ascend",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "3",
    sorter: (a, b) => a.price.localeCompare(b.price),
    defaultSortOrder: "ascend",
  },
  {
    title: "Total Price",
    dataIndex: "totalPrice",
    key: "4",
    sorter: (a, b) => a.totalPrice.localeCompare(b.totalPrice),
    defaultSortOrder: "ascend",
  },
];

const ViewOrder = ({ open, onClose, dataArr }) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setdata();
  }, []);

  const setdata = () => {
    if (dataArr.lenght !== 0) {
      let tableData = [];
      dataArr?.order_items?.map((dataSet, id) => {
        let productName = dataSet?.product_name;

        dataSet?.portions?.map((items, x) => {
          let data = {
            key: x,
            itemName: productName,
            size: items?.portion_label,
            qty: items?.qty,
            price: items?.unit_price,
            totalPrice: items?.total_amount,
          };
          tableData.push(data);
        });
      });
      setTableData(tableData);
    } else {
      setTableData([]);
    }
  };

  return (
    <Drawer
      title={"Order Details"}
      placement="right"
      size={"large"}
      onClose={onClose}
      open={open}
      className="text-center"
    >
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col gap-2 w-[60%] justify-center items-start">
          <div className="flex flex-col">
            <Typography className="text-sm font-semibold text-left text-text_blue">
              Date & Time
            </Typography>
            <Typography className="text-base font-medium text-left text-text_black2">
              {dataArr.created_at}
            </Typography>
          </div>
          <div className="flex flex-col">
            <Typography className="text-sm font-semibold text-left text-text_blue">
              Order ID:
            </Typography>
            <Typography className="text-base font-medium text-left text-text_black2">
              {dataArr.order_number}
            </Typography>
          </div>
          <div className="flex flex-col">
            <Typography className="text-sm font-semibold text-left text-text_blue">
              Payment Type:
            </Typography>
            <Typography className="text-base font-medium text-left text-text_black2">
              {dataArr.payment_data}
            </Typography>
          </div>
          <Divider />
        </div>
        <div className="flex flex-col gap-2 w-[60%] justify-center items-start">
          <div className="flex flex-col">
            <Typography className="text-sm font-semibold text-left text-text_blue">
              Seller Name & Type:
            </Typography>
            <Typography className="text-base font-medium text-left text-text_black2">
              {dataArr?.store_name + "-" + dataArr?.category_type}
            </Typography>
          </div>
          <div className="flex flex-col">
            <Typography className="text-sm font-semibold text-left text-text_blue">
              Address:
            </Typography>
            <Typography className="text-base font-medium text-left text-text_black2">
              {dataArr.store_location}
            </Typography>
          </div>
          <div className="flex flex-col">
            <Typography className="text-sm font-semibold text-left text-text_blue">
              Contact No:
            </Typography>
            <Typography className="text-base font-medium text-left text-text_black2">
              {dataArr.user_mobile_number}
            </Typography>
          </div>
          <Divider />
        </div>
        <div className="flex flex-col gap-2 w-[60%] justify-center items-start">
          <div className="flex flex-col">
            <Typography className="text-sm font-semibold text-left text-text_blue">
              Customer Name
            </Typography>
            <Typography className="text-base font-medium text-left text-text_black2">
              {dataArr?.user_fisrt_name + " " + dataArr?.user_last_name}
            </Typography>
          </div>
          <div className="flex flex-col">
            <Typography className="text-sm font-semibold text-left text-text_blue">
              Address:
            </Typography>
            <Typography className="text-base font-medium text-left text-text_black2">
              {dataArr?.delivery_address}
            </Typography>
          </div>
          <div className="flex flex-col">
            <Typography className="text-sm font-semibold text-left text-text_blue">
              Contact No:
            </Typography>
            <Typography className="text-base font-medium text-left text-text_black2">
              {dataArr.user_mobile_number}
            </Typography>
          </div>
          <Divider />
        </div>
        <div className="flex items-center justify-center w-full">
          <Table
            dataSource={tableData}
            columns={columns}
            loading={dataLoading}
            showSorterTooltip={false}
            pagination={{
              size: "small",
              defaultPageSize: 5,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "20"],
              showQuickJumper: true,
            }}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default ViewOrder;
