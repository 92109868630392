import { Card } from "antd";
import React from "react";

const OrdersStatRight = ({ value, title, color }) => {
  return (
    <Card
      hoverable={false}
      loading={false}
      className={`border-none shadow-2xl w-[120px] h-[178px] ${color} rounded-2xl `}
    >
      <div className="flex flex-col items-center gap-4">
        <span className="text-5xl font-semibold text-primary_white ">{value}</span>
        <span className="text-lg font-bold text-center text-text_black2 ">
          {title}
        </span>
      </div>
    </Card>
  );
};

export default OrdersStatRight;
