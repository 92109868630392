import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Dropdown, Space, Typography, message } from "antd";
import React from "react";

const Payments = () => {
  const items = [
    {
      label: "1st menu item",
      key: "1",
      icon: <UserOutlined />,
    },
    {
      label: "2nd menu item",
      key: "2",
      icon: <UserOutlined />,
    },
    {
      label: "3rd menu item",
      key: "3",
      icon: <UserOutlined />,
      danger: true,
    },
    {
      label: "4rd menu item",
      key: "4",
      icon: <UserOutlined />,

      danger: true,
      disabled: true,
    },
  ];

  const handleMenuClick = (e) => {
    message.info("Click on menu item.");
    console.log("click", e);
  };

  const menuProps = {
    items,
    onclick: handleMenuClick,
  };

  return (
    <div className="flex items-center justify-center w-full">
      <div className="flex flex-col w-full">
        <div className="flex flex-col w-full border-border_graydark shadow-2xl border-[1px] rounded-2xl">
          <div className="flex flex-row items-center w-full mt-2">
            <div className="flex flex-row items-center justify-start w-full gap-4 sm:gap-24">
              <Typography className="ml-6 font-semibold text-text_black1">
                Total Earnings
              </Typography>
              <Typography className="text-2xl font-bold text-text_blue">
                {"157,014.00"}
              </Typography>
            </div>
            <div className="flex justify-end w-full mr-6 ">
              <Dropdown className="bg-border_graydark rounded-2xl " menu={menuProps}>
                <Button className="items-center flex h-[24px] w-[200px]">
                  <Space className="font-semibold gap-28 text-start">
                    Today
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </div>
          </div>
          <div className="flex flex-col items-center w-full gap-4 mt-4 mb-4 ml-3">
            <div className="flex flex-row items-center w-full gap-8">
              <Card
                hoverable={false}
                loading={false}
                className="bg-[#59DCE5] border-none shadow-2xl w-1/4 h-16 rounded-2xl"
              >
                <div className="flex flex-col items-center justify-center w-full -mt-6">
                  <Typography className="flex justify-center text-base font-semibold text-text_black1 ">
                    COD
                  </Typography>
                  <Typography className="flex justify-center text-lg font-bold text-center text-text_black1">
                    {"120,014.00"}
                  </Typography>
                </div>
              </Card>
              <Card
                hoverable={false}
                loading={false}
                className="bg-[#9FE448] border-none shadow-2xl w-1/4 h-16 rounded-2xl"
              >
                <div className="flex flex-col items-center justify-center w-full -mt-6">
                  <Typography className="flex justify-center text-base font-semibold text-text_black1 ">
                  Online
                  </Typography>
                  <Typography className="flex justify-center text-lg font-bold text-center text-text_black1">
                    {"32,020.00"}
                  </Typography>
                </div>
              </Card>
              <div className="flex flex-row items-center justify-center w-1/3 h-12 border-2 rounded-full border-primary_yellowdark">
                <Typography className="text-base text-text_blue1 ">
                  Delivery hero’s Receivables list
                </Typography>
              </div>
            </div>
            <div className="flex flex-row items-center w-full gap-8">
              <Card
                hoverable={false}
                loading={false}
                className="bg-[#FFDE81] border-none shadow-2xl w-1/4 h-16 rounded-2xl"
              >
                <div className="flex flex-col items-center justify-center w-full -mt-6">
                  <Typography className="flex justify-center text-base font-semibold text-text_black1 ">
                  Total Delivery Chargers
                  </Typography>
                  <Typography className="flex justify-center text-lg font-bold text-center text-text_black1">
                    {"29,014.00"}
                  </Typography>
                </div>
              </Card>
              <Card
                hoverable={false}
                loading={false}
                className="bg-[#DCDCDC] border-none shadow-2xl w-1/4 h-16 rounded-2xl"
              >
                <div className="flex flex-col items-center justify-center w-full -mt-6">
                  <Typography className="flex justify-center text-base font-semibold text-text_black1 ">
                  20% Batta fee total
                  </Typography>
                  <Typography className="flex justify-center text-lg font-bold text-center text-text_black1">
                    {"2900.00"}
                  </Typography>
                </div>
              </Card>
              <div className="flex flex-row items-center justify-center w-1/3 h-12 border-2 rounded-full border-primary_yellowdark">
                <Typography className="text-base text-text_blue1 ">
                  Seller’s payment list
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full mt-8">
          <div className="flex flex-row w-full">
            <div className="flex justify-start w-full ml-8">
              <Typography className="text-base font-semibold text-text_black1">
                Late Receivables from Delivery Heroes
              </Typography>
            </div>
            <div className="flex items-center justify-end w-full mr-2">
              <Typography className="text-base font-semibold underline text-text_black1">
                See all
              </Typography>
            </div>
          </div>
          <div className="flex flex-row w-full mt-4 ">
            <Card
              hoverable={false}
              loading={false}
              className="bg-[#E9E8E8] flex flex-col border-[#E9E8E8] shadow-2xl sm:w-[250px] w-auto h-auto rounded-xl"
            >
              <div className="flex flex-col items-center justify-center w-full gap-3 -mt-4 -mb-4">
                <div className="flex flex-row items-center w-full gap-8">
                  <div className="flex flex-col gap-2">
                    <Typography className="text-sm font-semibold text-text_black1 ">
                      Delivery Hero Name
                    </Typography>
                    <Typography className="text-sm font-bold text-text_blue1">
                      REG NO {"123456"}
                    </Typography>
                  </div>
                  <div className="flex items-center">
                    <div className="flex flex-col items-center justify-center rounded-full w-14 h-14 bg-primary_white">
                      <Typography className=" text-xs font-bold text-[#DC0303]">
                        {"2"}
                      </Typography>
                      <Typography className="text-xs font-bold text-[#DC0303]">
                        Days late
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row w-full">
                  <Typography className="text-[#000000E5] justify-start w-full flex text-base">
                    Due
                  </Typography>
                  <Typography className="text-[#000000E5] font-bold flex w-full justify-end text-xl">
                    {"4,014.00"}
                  </Typography>
                </div>
                <div className="flex items-center justify-center w-full">
                  <Button className="bg-[#E74848] rounded-xl w-52 h-9 text-white">
                    REMINDER
                  </Button>
                </div>
              </div>
            </Card>
          </div>
          <div className="flex flex-row gap-6 pr-6 mt-12 mb-12">
            <Card
              hoverable={false}
              loading={false}
              className="border-[2px] bg-primary_white flex justify-center border-primary_yellowdark sm:w-[190px] sm:h-[100px] shadow-2xl rounded-3xl"
            >
              <div className="flex w-full mt-2 ">
                <Typography className="text-text_blue1 w-[150px] text-center text-base font-semibold">Manage Seller’s Payments</Typography>
              </div>
            </Card>
            <Card
              hoverable={false}
              loading={false}
              className="border-[2px] bg-primary_white flex justify-center border-primary_yellowdark sm:w-[190px] sm:h-[100px] shadow-2xl rounded-3xl"
            >
              <div className="flex w-full mt-2 ">
                <Typography className="text-text_blue1 w-[150px] text-center text-base font-semibold">Manage Delivery Hero’s Payments </Typography>
              </div>
            </Card>
            <Card
              hoverable={false}
              loading={false}
              className="border-[2px] bg-primary_white flex justify-center border-primary_yellowdark sm:w-[190px] sm:h-[100px] shadow-2xl rounded-3xl"
            >
              <div className="flex w-full mt-6 ">
                <Typography className="text-text_blue1 w-[150px] text-center text-base font-semibold">All transactions</Typography>
              </div>
            </Card>
            <Card
              hoverable={false}
              loading={false}
              className="border-[2px] bg-primary_white flex justify-center border-primary_yellowdark sm:w-[190px] sm:h-[100px] shadow-2xl rounded-3xl"
            >
              <div className="flex w-full mt-6 ">
                <Typography className="text-text_blue1 w-[150px] text-center text-base font-semibold">Reports</Typography>
              </div>
            </Card>
            <Card
              hoverable={false}
              loading={false}
              className="border-[2px] bg-primary_white flex justify-center border-primary_yellowdark sm:w-[190px] sm:h-[100px] shadow-2xl rounded-3xl"
            >
              <div className="flex w-full mt-6 ">
                <Typography className="text-text_blue1 w-[150px] text-center text-base font-semibold">Accounts</Typography>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payments;
