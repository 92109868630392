import { Button, Typography } from "antd";
import React, { useState } from "react";
import { ResetAdminPassword } from "../componants";

const Settings = () => {
  const [formChanger, setFormChanger] = useState(0);
  return (
    <div className="flex flex-col w-full">
      <Typography className=" flex text-left ml-2 text-lg  min-w-[140px] font-semibold text-text_blacklight">
        Settings
      </Typography>
      <div className="flex items-center justify-start w-full mt-4">
        <Button
          onClick={() => {
            setFormChanger(1);
          }}
          disabled={formChanger === 1 ? true : false}
          className="flex justify-center text-sm font-medium text-center w-60 rounded-3xl bg-primary_bluedark text-primary_white"
        >
          Reset Your Password
        </Button>
      </div>
      <div className="flex items-center justify-center w-full">
        {formChanger === 1 ? (
          <ResetAdminPassword setFormChanger={setFormChanger} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Settings;
