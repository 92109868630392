import { Form, Input, Table, Typography, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import "../styles/Orders.css";
import { OrdersStatRight, RecentPickupRequests } from "../componants";
import axios from "axios";
import {
  base_url,
  config,
  openErrorNotification,
  openWarningNotification,
} from "../helpers/headers";
import ViewRecentPickupRequests from "../componants/orders/ViewOrder";

const columns = [
  {
    title: "Seller",
    dataIndex: "seller",
    key: "0",
    // sorter: (a, b) => a.seller?.localeCompare(b.seller),
    defaultSortOrder: "ascend",
  },
  {
    title: "Order ID",
    dataIndex: "orderId",
    key: "1",
    sorter: (a, b) => a.orderId?.localeCompare(b.orderId),
    defaultSortOrder: "ascend",
  },
  {
    title: "Pickup location",
    dataIndex: "pickupLocation",
    key: "2",
    sorter: (a, b) => a.pickupLocation?.localeCompare(b.pickupLocation),
    defaultSortOrder: "ascend",
  },
  {
    title: "Deliver location",
    dataIndex: "deliverLocation",
    key: "3",
    sorter: (a, b) => a.deliverLocation?.localeCompare(b.deliverLocation),
    defaultSortOrder: "ascend",
  },
  {
    title: "Payment type",
    dataIndex: "paymentType",
    key: "4",
    sorter: (a, b) => a.paymentType?.localeCompare(b.paymentType),
    defaultSortOrder: "ascend",
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "5",
    sorter: (a, b) => a.total - b.total,
    defaultSortOrder: "ascend",
  },
  {
    title: "Options",
    dataIndex: "options",
    key: "6",
  },
];

const rangePresets = [
  {
    label: "Last 7 Days",
    value: [dayjs().add(-7, "d"), dayjs()],
  },
  {
    label: "Last 14 Days",
    value: [dayjs().add(-14, "d"), dayjs()],
  },
  {
    label: "Last 30 Days",
    value: [dayjs().add(-30, "d"), dayjs()],
  },
  {
    label: "Last 90 Days",
    value: [dayjs().add(-90, "d"), dayjs()],
  },
];

const Orders = () => {
  const [state, setState] = useState(1);
  const [dataLoading, setDataLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [dataArr, setDataArr] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [recentRequests, setRecentRequests] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const { RangePicker } = DatePicker;

  const action = (index) => {
    setState(index);
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };
  const handleOpenDrawer = (dataSet) => {
    setOrderData(dataSet);
    setDrawerVisible(true);
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setDateRange(dateStrings);
    } else {
      setDateRange([]);
    }
  };

  useEffect(() => {
    getData(0);
    recent_request();
  }, []);

  const recent_request = () => {
    const data = {
      user_id: 0,
      order_id: 0,
      category_id: 0,
      shop_id: 0,
      status: 0, //0-pending, 1-approved, 2-prepared, 3-pickup, 4-completed
      start_date: null,
      end_date: null,
    };

    axios
      .post(base_url + "order/list", data, config)
      .then((response) => {
        if (response.data.success) {
          setRecentRequests(response.data.output);
        } else {
          openWarningNotification(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification(error.response.data.message);
        } else if (error.response.status === 500) {
          openErrorNotification("Internal Server Error!" + error.message);
        } else {
          openErrorNotification("Error!" + error.message);
        }
      });
  };

  const getData = (status) => {
    setDataLoading(true);

    const data = {
      user_id: 0,
      order_id: 0,
      category_id: 0,
      shop_id: 0,
      status: status, //0-pending, 1-approved, 2-prepared, 3-pickup, 4-completed, -2-all, -1-cancel
      start_date: dateRange[0],
      end_date: dateRange[1],
    };

    axios
      .post(base_url + "order/list", data, config)
      .then((response) => {
        if (response.data.success) {
          setDataArr(response.data.output);
          setData(response.data.output);
        } else {
          openWarningNotification(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification(error.response.data.message);
        } else if (error.response.status === 500) {
          openErrorNotification("Internal Server Error!" + error.message);
        } else {
          openErrorNotification("Error!" + error.message);
        }
      });
  };

  const setData = (dataArr) => {
    if (dataArr.length !== 0) {
      let tableData = [];
      dataArr?.forEach((dataSet, id) => {
        let data = {
          key: id,
          seller: dataSet?.store_name ? (
            dataSet?.store_name
          ) : (
            <Typography className="text-text_reddark">
              Seller Deactivated
            </Typography>
          ),
          orderId: dataSet?.order_number,
          pickupLocation: dataSet?.store_location,
          deliverLocation: dataSet?.delivery_address,
          paymentType: dataSet?.payment_data,
          total: dataSet?.total,
          options: (
            <div className="flex flex-row items-center gap-2">
              <a
                onClick={() => {
                  handleOpenDrawer(dataSet);
                }}
              >
                <EyeOutlined className="text-lg text-text_blue" />
              </a>
            </div>
          ),
        };
        tableData.push(data);
      });
      setTableData(tableData);
    } else {
      setTableData([]);
    }
    setDataLoading(false);
  };

  const searchData = (text) => {
    setDataLoading(true);
    let filtered = dataArr?.filter((data) => {
      return (
        data.store_name?.toLowerCase().includes(text.toLowerCase()) ||
        data.order_number?.toLowerCase().includes(text.toLowerCase()) ||
        data.store_location?.toLowerCase().includes(text.toLowerCase()) ||
        data.delivery_address?.toLowerCase().includes(text.toLowerCase()) ||
        data.total?.toString().includes(text.toString())
      );
    });
    setData(filtered);
  };

  return (
    <div className="flex flex-col w-full">
      <div className="items-center w-full text-center">
        <span className="text-base font-medium text-text_blue1">
          RECENT NEW PICK-UP REQUSTS
        </span>
      </div>
      <div className="flex flex-row w-full gap-4 mt-4 overflow-x-scroll">
        {recentRequests.map((data, id) => (
          <RecentPickupRequests key={id} dataArr={data} />
        ))}
      </div>

      <div className="flex flex-col w-full gap-2 mt-6 lg:flex-row lg:gap-6">
        <div className="flex flex-col items-center w-full ">
          <div className="flex flex-row items-center justify-center w-full gap-8 ml-4 lg:justify-normal">
            <Typography className="text-text_blue1 justify-start items-center min-w-[90px] w-auto font-semibold">
              ALL ORDERS
            </Typography>
            <Form className="flex w-auto lg:w-[50%]">
              <div className="flex flex-row w-full items-center h-auto sm:h-[35px] rounded-2xl">
                <Input
                  className="rounded-full border-border_blue border-[1px]"
                  type="text"
                  placeholder="Search Orders by name or ID"
                  onChange={(e) => {
                    searchData(e.target.value);
                  }}
                  suffix={
                    <SearchOutlined className="text-base font-bold site-form-item-icon text-border_blue" />
                  }
                />
              </div>
            </Form>
            <div className="flex items-center justify-end w-[30%]">
              <RangePicker
                className="w-full rounded-2xl border-border_blue border-[1px]"
                presets={rangePresets}
                onChange={onRangeChange}
              />
            </div>
          </div>
          <div className="flex flex-col w-full pt-4">
            {/* tabs */}
            <div className="flex flex-row justify-between w-full gap-[2px]">
              <div className="flex items-center  w-[48%]">
                <div
                  onClick={() => {
                    action(1);
                    getData(0);
                  }}
                  className={`${state === 1 ? "active-tab1" : "tab1"}`}
                >
                  Just Placed
                </div>
              </div>
              <div className="flex items-center  w-[48%]">
                <div
                  onClick={() => {
                    action(2);
                    getData(1);
                  }}
                  className={`${state === 2 ? "active-tab1" : "tab2"}`}
                >
                  Preparing
                </div>
              </div>
              <div className="flex items-center  w-[48%]">
                <div
                  onClick={() => {
                    action(3);
                    getData(2);
                  }}
                  className={`${state === 3 ? "active-tab1" : "tab3"}`}
                >
                  Waiting for Pick-up
                </div>
              </div>
              <div className="flex items-center  w-[48%]">
                <div
                  onClick={() => {
                    action(4);
                    getData(3);
                  }}
                  className={`${state === 4 ? "active-tab1" : "tab4"}`}
                >
                  On the way
                </div>
              </div>
              <div className="flex items-center  w-[48%]">
                <div
                  onClick={() => {
                    action(5);
                    getData(4);
                  }}
                  className={`${state === 5 ? "active-tab1" : "tab5"}`}
                >
                  Delivered
                </div>
              </div>
              <div className="flex items-center  w-[48%]">
                <div
                  onClick={() => {
                    action(6);
                    getData(-1);
                  }}
                  className={`${state === 6 ? "active-tab1" : "tab6"}`}
                >
                  Cancelled
                </div>
              </div>
            </div>

            {/* contents*/}
            <div className="relative">
              <div
                className={`${
                  state === 1 ? "active-content2 shadow-lg" : "content1"
                }`}
              >
                <div className="w-full">
                  <Table
                    dataSource={tableData}
                    columns={columns}
                    loading={dataLoading}
                    showSorterTooltip={false}
                    pagination={{
                      size: "small",
                      defaultPageSize: 5,
                      showSizeChanger: true,
                      pageSizeOptions: ["5", "10", "20"],
                      showQuickJumper: true,
                    }}
                  />
                  {drawerVisible ? (
                    <ViewRecentPickupRequests
                      open={drawerVisible}
                      onClose={handleCloseDrawer}
                      dataArr={orderData}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div
                className={`${
                  state === 2 ? "active-content2 shadow-lg" : "content1"
                }`}
              >
                <div className="w-full">
                  <Table
                    dataSource={tableData}
                    columns={columns}
                    loading={dataLoading}
                    showSorterTooltip={false}
                    pagination={{
                      size: "small",
                      defaultPageSize: 5,
                      showSizeChanger: true,
                      pageSizeOptions: ["5", "10", "20"],
                      showQuickJumper: true,
                    }}
                  />
                </div>
              </div>
              <div
                className={`${
                  state === 3 ? "active-content2 shadow-lg" : "content1"
                }`}
              >
                <div className="w-full">
                  <Table
                    dataSource={tableData}
                    columns={columns}
                    loading={dataLoading}
                    showSorterTooltip={false}
                    pagination={{
                      size: "small",
                      defaultPageSize: 5,
                      showSizeChanger: true,
                      pageSizeOptions: ["5", "10", "20"],
                      showQuickJumper: true,
                    }}
                  />
                </div>
              </div>
              <div
                className={`${
                  state === 4 ? "active-content2 shadow-lg" : "content1"
                }`}
              >
                <div className="w-full">
                  <Table
                    dataSource={tableData}
                    columns={columns}
                    loading={dataLoading}
                    showSorterTooltip={false}
                    pagination={{
                      size: "small",
                      defaultPageSize: 5,
                      showSizeChanger: true,
                      pageSizeOptions: ["5", "10", "20"],
                      showQuickJumper: true,
                    }}
                  />
                </div>
              </div>
              <div
                className={`${
                  state === 5 ? "active-content2 shadow-lg" : "content1"
                }`}
              >
                <div className="w-full">
                  <Table
                    dataSource={tableData}
                    columns={columns}
                    loading={dataLoading}
                    showSorterTooltip={false}
                    pagination={{
                      size: "small",
                      defaultPageSize: 5,
                      showSizeChanger: true,
                      pageSizeOptions: ["5", "10", "20"],
                      showQuickJumper: true,
                    }}
                  />
                </div>
              </div>
              <div
                className={`${
                  state === 6 ? "active-content2 shadow-lg" : "content1"
                }`}
              >
                <div className="w-full">
                  <Table
                    dataSource={tableData}
                    columns={columns}
                    loading={dataLoading}
                    showSorterTooltip={false}
                    pagination={{
                      size: "small",
                      defaultPageSize: 5,
                      showSizeChanger: true,
                      pageSizeOptions: ["5", "10", "20"],
                      showQuickJumper: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-end gap-2 pt-12 sm:gap-6">
          <OrdersStatRight
            value={"3"}
            title={"Late Pick-ups"}
            color={"bg-primary_red"}
          />
          <OrdersStatRight
            value={"0"}
            title={"Late Deliveries"}
            color={"bg-primary_pinkdark"}
          />
        </div>
      </div>
    </div>
  );
};

export default Orders;
