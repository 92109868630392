import axios from "axios";
import { base_url } from "./headers";
//const url = import.meta.env.BASE_URL;

export async function adminLogin(login_type, email, password) {
  const data = {
    user_type_id: login_type,
    email: email,
    password: password,
  };
  return await axios.post(base_url + "user/login", data);
}
