import { Card, Typography } from "antd";
import React from "react";

const DashboardStat = ({title, value, color,icon}) => {
  return (
    <Card
      hoverable={false}
      loading={false}
      className="bg-primary_white border-border_gray min-w-[130px] max-w-[210px] shadow-2xl sm:w-52 sm:h-28 rounded-2xl"
    >
      <div className="flex flex-col items-center gap-2 -mt-4">
        <img src={icon} width={25}/>
        <Typography className="text-xs font-semibold lg:text-sm ">
          {title}
        </Typography>
        <Typography className={`text-base font-bold ${color} lg:text-2xl`}>
          {value}
        </Typography>
      </div>
    </Card>
  );
};
 
export default DashboardStat;
