import { Button, Form, Typography } from "antd";
import React, { useState } from "react";
import { InputOTP } from "antd-input-otp";
import axios from "axios";
import CountdownTimer from "./CountdownTimer";
import {
  base_url,
  openErrorNotification,
  openSuccessNotification,
  openWarningNotification,
} from "../../helpers/headers";

const VerifyOTP = ({ setFormChanger, email }) => {
  const [counterState, setCounterState] = useState(0);
  const [buttonDisable, setButtonDisable] = useState(false);

  const checkOTP = (formData) => {
    const data = {
      email: email,
      otp:
        formData.otp[0] +
        formData.otp[1] +
        formData.otp[2] +
        formData.otp[3] +
        formData.otp[4] +
        formData.otp[5],
    };

    axios
      .post(base_url + "verify/admin/otp", data)
      .then((response) => {
        if (response.data.success && response.data.message === "OTP matched") {
          openSuccessNotification(response.data.message);
          setFormChanger(2);
        } else {
          openWarningNotification(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification("Error!" + error.response.data.message);
        } else if (error.response.status === 500) {
          openErrorNotification("Internal Server Error!" + error.message);
        } else {
          openErrorNotification("Error!" + error.message);
        }
      });
  };

  const resendOTP = () => {
    const data = {
      email: email,
    };

    axios
      .post(base_url + "request/admin/otp", data)
      .then((response) => {
        if (
          response.data.success &&
          response.data.message === "OTP generated."
        ) {
          console.log(response.data.output.OTP);
          setCounterState(0);
          setButtonDisable(false);
        } else {
          openWarningNotification(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification("Error!" + error.response.data.message);
        } else if (error.response.status === 500) {
          openErrorNotification("Internal Server Error!" + error.message);
        } else {
          openErrorNotification("Error!" + error.message);
        }
      });
  };

  return (
    <div className="flex flex-col items-center justify-center w-4/6 min-w-[300px] bg-header_yellow ml-72 h-1/2 rounded-3xl">
      <Typography className="text-xl font-semibold text-text_blue1">
        Reset your Password
      </Typography>
      <div className="flex items-center justify-center w-full">
        <Form
          name="reset_password"
          className="flex flex-col w-4/5 mt-4"
          initialValues={{
            remember: true,
          }}
          onFinish={checkOTP}
        >
          <Typography className="text-sm font-medium text-text_blacklight">
            Please enter the verification code sent to your email
          </Typography>
          <Form.Item
            name="otp"
            rules={[
              {
                required: true,
                type: "text",
                message: "Invalid Email Address!",
              },
            ]}
            className="mt-4"
          >
            <InputOTP
              length={6}
              masked={false}
              numeric={true}
              autoFocus={true}
              className="text-sm"
              //autoValidate={true}
              //onValidate={"validateCallback"}
              //onChange={"changeCallback"}
            />
          </Form.Item>
          <div className="flex justify-center w-full mb-4 -mt-5">
            {counterState === 0 ? (
              <CountdownTimer
                initialSeconds={120}
                setCounterState={setCounterState}
                setButtonDisable={setButtonDisable}
              />
            ) : (
              <Typography className="cursor-pointer" onClick={resendOTP}>
                Resend
              </Typography>
            )}
          </div>
          <Form.Item>
            <div className="flex justify-center w-full">
              <Button
                type="primary"
                disabled={buttonDisable}
                htmlType="submit"
                className="w-5/6 h-8 text-sm font-semibold text-center text-primary_white bg-primary_bluedark"
              >
                VERIFY
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default VerifyOTP;
