import { DownOutlined } from "@ant-design/icons";
import "../styles/Dashboard.css";
import { Dropdown, Space, Typography, message } from "antd";
import React, { useState } from "react";
import {
  Food1,
  Food2,
  Food3,
  Food4,
  completed_order,
  ongoing_order,
  total_canceled,
  total_earning,
  total_order,
} from "../assets";
import {
  DashboardStat,
  DashboardStatLeft,
  DashboardStatRight,
  DashboardStatRightBox,
} from "../componants";

export default function Dashboard() {
  const [totalOrders, SetTotalOrders] = useState(224);
  const [ongoingOrders, SetOngoingOrders] = useState(102);
  const [completedOrders, SetCompletedOrders] = useState(122);
  const [cancellations, SetCancellations] = useState(0);
  const [earnings, SetEarnings] = useState(157014.0);

  const [state, setState] = useState(1);

  const action = (index) => {
    setState(index);
  };

  const onChange = (key) => {
    console.log(key);
  };

  const handleMenuClick = (e) => {
    message.info("Click on menu item.");
    console.log("click", e);
  };

  const items = [
    {
      label: "Today",
      key: "1",
    },
    {
      label: "Last 3 days",
      key: "2",
    },
    {
      label: "Last 7 days",
      key: "3",
    },
    {
      label: "Last 14 days",
      key: "4",
    },
    {
      label: "Last 30 days",
      key: "5",
    },
  ];

  const menuProps = {
    items,
    onclick: handleMenuClick,
  };

  return (
    <div className="flex flex-col w-full gap-4 sm:p-2 sm:gap-5">
      <div className="flex justify-end ml-4 ">
        <Dropdown
          className="flex items-center justify-center w-32 bg-border_graydark rounded-2xl md:w-44 h-7"
          menu={menuProps}
          trigger={["click"]}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space className="text-start">
              Today
              <DownOutlined className="ml-4 md:ml-10" />
            </Space>
          </a>
        </Dropdown>
      </div>
      <div className="flex flex-row items-center w-full gap-2 overflow-x-scroll lg:overflow-hidden sm:gap-6 sm:justify-evenly">
        <DashboardStat
          title="Total Orders"
          value={totalOrders}
          color={"text-text_blue"}
          icon={total_order}
        />
        <DashboardStat
          title="Ongoing Orders"
          value={ongoingOrders}
          color={"text-text_green"}
          icon={ongoing_order}
        />
        <DashboardStat
          title="Completed Orders"
          value={completedOrders}
          color={"text-text_gray"}
          icon={completed_order}
        />
        <DashboardStat
          title="Cancellations"
          value={cancellations}
          color={"text-text_reddark"}
          icon={total_canceled}
        />
        <DashboardStat
          title="Total Earning (LKR)"
          value={earnings}
          color={"text-text_pink"}
          icon={total_earning}
        />
      </div>
      <div className="flex justify-center w-full mt-2 lg:w-1/2 ">
        <span className="-mb-2 text-sm font-semibold text-text_blue1 ">
          TOP SELLING ITEMS BY CATEGORY
        </span>
      </div>
      <div className="flex flex-col justify-center w-full gap-6 lg:gap-0 lg:justify-evenly lg:flex-row">
        <div className="flex flex-col justify-center w-full lg:justify-start lg:w-1/2 ">
          <div className="flex flex-col w-full">
            {/* tabs */}
            <div className="flex flex-row w-full ">
              <div className="flex items-center min-w-[80px] w-[48%]">
                <div
                  onClick={() => {
                    action(1);
                  }}
                  className={`${
                    state === 1 ? "active-dashboard_tab" : "dashboard_tab"
                  }`}
                >
                  Restaurant
                </div>
              </div>
              <div className="flex items-center min-w-[80px] w-[48%]">
                <div
                  onClick={() => {
                    action(2);
                  }}
                  className={`${
                    state === 2 ? "active-dashboard_tab" : "dashboard_tab"
                  }`}
                >
                  Dark Kitchen
                </div>
              </div>
              <div className="flex items-center min-w-[80px] w-[48%]">
                <div
                  onClick={() => {
                    action(3);
                  }}
                  className={`${
                    state === 3 ? "active-dashboard_tab" : "dashboard_tab"
                  }`}
                >
                  Groceries
                </div>
              </div>
              <div className="flex items-center min-w-[80px] w-[48%]">
                <div
                  onClick={() => {
                    action(4);
                  }}
                  className={`${
                    state === 4 ? "active-dashboard_tab" : "dashboard_tab"
                  }`}
                >
                  Mini Mart
                </div>
              </div>
              <div className="flex items-center min-w-[80px] w-[48%]">
                <div
                  onClick={() => {
                    action(5);
                  }}
                  className={`${
                    state === 5 ? "active-dashboard_tab" : "dashboard_tab"
                  }`}
                >
                  Crafts
                </div>
              </div>
            </div>

            {/* contents*/}
            <div className="relative">
              <div
                className={`${
                  state === 1 ? "active-dashboard_content" : "dashboard_content"
                }`}
              >
                <div className="flex flex-col w-full gap-6 h-[400px]">
                  <div className="flex flex-row items-center justify-end w-full pr-10 gap-14">
                    <Typography className="text-xs font-semibold text-text_blacklight">
                      Sold QTY
                    </Typography>
                    <Typography className="text-xs font-semibold text-text_blacklight">
                      Unit Price
                    </Typography>
                  </div>
                  <div className="flex flex-col w-full gap-4 overflow-y-scroll">
                    <DashboardStatLeft
                      img={Food1}
                      fname={"Lorem ipsum dolor sit"}
                      rname={"resturant 1"}
                      sname={"seller 1"}
                      pid={"0012345"}
                    />
                    <DashboardStatLeft
                      img={Food2}
                      fname={"Lorem ipsum dolor sit"}
                      rname={"resturant 1"}
                      sname={"seller 1"}
                      pid={"0012345"}
                    />
                    <DashboardStatLeft
                      img={Food3}
                      fname={"Lorem ipsum dolor sit"}
                      rname={"resturant 1"}
                      sname={"seller 1"}
                      pid={"0012345"}
                    />
                    <DashboardStatLeft
                      img={Food4}
                      fname={"Lorem ipsum dolor sit"}
                      rname={"resturant 1"}
                      sname={"seller 1"}
                      pid={"0012345"}
                    />
                  </div>
                  {/* {tabItems.map((data, i) => {
                const id = String(i + 1);
                return {
                  label: (
                    <div className="text-base font-semibold">{data.label}</div>
                  ),
                  key: id,
                  children: `Content of card tab ${id}`,
                };
              })}
              */}
                </div>
              </div>
              <div
                className={`${
                  state === 2 ? "active-dashboard_content" : "dashboard_content"
                }`}
              >
                <div className="flex flex-col w-full">
                  <div className="flex flex-row items-center justify-end w-full gap-16">
                    <Typography className="text-xs font-semibold">
                      Sold QTY
                    </Typography>
                    <Typography className="text-xs font-semibold">
                      Unit Price
                    </Typography>
                  </div>
                  {/* {tabItems.map((data, i) => {
                const id = String(i + 1);
                return {
                  label: (
                    <div className="text-base font-semibold">{data.label}</div>
                  ),
                  key: id,
                  children: `Content of card tab ${id}`,
                };
              })}
              */}
                </div>
              </div>
              <div
                className={`${
                  state === 3 ? "active-dashboard_content" : "dashboard_content"
                }`}
              >
                <div className="flex flex-col w-full">
                  <div className="flex flex-row items-center justify-end w-full gap-16">
                    <Typography className="text-xs font-semibold">
                      Sold QTY
                    </Typography>
                    <Typography className="text-xs font-semibold">
                      Unit Price
                    </Typography>
                  </div>
                  {/* {tabItems.map((data, i) => {
                const id = String(i + 1);
                return {
                  label: (
                    <div className="text-base font-semibold">{data.label}</div>
                  ),
                  key: id,
                  children: `Content of card tab ${id}`,
                };
              })}
              */}
                </div>
              </div>
              <div
                className={`${
                  state === 4 ? "active-dashboard_content" : "dashboard_content"
                }`}
              >
                <div className="flex flex-col w-full">
                  <div className="flex flex-row items-center justify-end w-full gap-16">
                    <Typography className="text-xs font-semibold">
                      Sold QTY
                    </Typography>
                    <Typography className="text-xs font-semibold">
                      Unit Price
                    </Typography>
                  </div>
                  {/* {tabItems.map((data, i) => {
                const id = String(i + 1);
                return {
                  label: (
                    <div className="text-base font-semibold">{data.label}</div>
                  ),
                  key: id,
                  children: `Content of card tab ${id}`,
                };
              })}
              */}
                </div>
              </div>
              <div
                className={`${
                  state === 5 ? "active-dashboard_content" : "dashboard_content"
                }`}
              >
                <div className="flex flex-col w-full">
                  <div className="flex flex-row items-center justify-end w-full gap-16">
                    <Typography className="text-xs font-semibold">
                      Sold QTY
                    </Typography>
                    <Typography className="text-xs font-semibold">
                      Unit Price
                    </Typography>
                  </div>

                  {/* { tabItems.map((data, i) => {
                        const id = String(i + 1);
                          return {
                        label: (
                        <div className="text-base font-semibold">{data.label}</div>
                        ),
                        key: id,
                        children: `Content of card tab ${id}`,
                        };
                          })}
                    */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center w-full lg:w-1/2 ">
          <div className="flex flex-row w-full gap-2 justify-evenly lg:gap-4 lg:justify-end">
            <div className="flex flex-col gap-4">
              <DashboardStatRightBox
                value={"23"}
                title={"New Orders For pickup"}
                color={"text-text_reddark"}
              />
              <DashboardStatRightBox
                value={"12"}
                title={"New Join Requests"}
                color={"text-text_blue"}
              />
              <DashboardStatRightBox
                value={"5"}
                title={"Seller Support Requests"}
                color={"text-text_pink"}
              />
            </div>
            <div className="flex flex-col gap-4">
              <DashboardStatRightBox
                value={"1"}
                title={"cancellation requests"}
                color={"text-text_red"}
              />
              <DashboardStatRightBox
                value={"3"}
                title={"Customer Support requsts"}
                color={"text-text_green"}
              />
              <DashboardStatRightBox
                value={"2"}
                title={"Late Payments"}
                color={"text-text_gray"}
              />
            </div>
            <div className="flex flex-col gap-4 ">
              <DashboardStatRight
                value={"3"}
                title={"Late Pick-ups"}
                bg={"bg-primary_red"}
              />
              <DashboardStatRight
                value={"0"}
                title={"Late Deliveries"}
                bg={"bg-primary_pinkdark"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
